import theme from 'src/theme/theme'
import styled from 'styled-components'
import { ToggleStylingProps } from './Toggle.types'

export const Container = styled.div<ToggleStylingProps>`
  padding: 4px 6px 4px 14px;
  background-color: rgba(0, 0, 0, 0.22);
  height: 34px;
  border-radius: 34px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Label = styled.div`
  color: #fff;
`

export const Button = styled.div<ToggleStylingProps>`
  margin-left: 8px;
  padding: 4px;
  width: 40px;
  height: 22px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.active ? 'flex-end' : 'flex-start')};
  background-color: ${(props) =>
    props.active ? theme.colors.secondary : theme.colors.tertiary};
`

export const Pin = styled.div<ToggleStylingProps>`
  background-color: #fff;
  width: 21px;
  height: 21px;
  border-radius: 38px;
`
