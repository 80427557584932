import React from 'react'
import styled from 'styled-components'
import { TableProps } from './Table.types'

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`

const Table: React.FC<TableProps> = ({ children }) => {
  return <Container>{children}</Container>
}

export default Table
