import React from 'react'
import { ButtonContainer } from './Button.styles'
import ButtonProps from './Button.types'

const Button: React.FC<ButtonProps> = ({
  title,
  disabled,
  onClick,
  variant,
  href,
}) => {
  const onClickHandler = () => {
    if (disabled) return
    if (!onClick) return
    onClick()
  }

  if (href)
    return (
      <ButtonContainer href={href} disabled={disabled}>
        {title}
      </ButtonContainer>
    )

  return (
    <ButtonContainer
      onClick={onClickHandler}
      disabled={disabled}
      variant={variant}
    >
      {title}
    </ButtonContainer>
  )
}

export default Button
