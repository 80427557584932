import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import { useNavigate } from 'react-router-dom'
import NumberLabel from 'src/components/Label/NumberLabel'
import Cell from 'src/components/Table/Cell'
import Table from 'src/components/Table/Table'
import THead from 'src/components/Table/THead'
import { Project, SimpleProject } from 'src/packages/api/types/Project'
import { getEmployeeHoursByDetails, sumHours } from 'src/pages/helper'
import {
  ProjectDetails,
  ProjectName,
} from 'src/pages/project/[id]/components/styles'
import { Scrollable } from 'src/types/scrollable.types'
import styled from 'styled-components'
import { CalenderView, EmployeeState } from '../EmployeesPage'
import { GetMonthsNextYear, MonthYearPair } from '../helper'

interface EmployeeProjectListProps {
  selectedEmployee: EmployeeState
  selectedMonth?: MonthYearPair
  selectedProject?: Project
  projects: Project[]
  view: CalenderView
}

const Container = styled.div<{ scrollable?: Scrollable }>`
  height: calc(100vh - 58px);
  width: 358px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-y: ${(props) => (props.scrollable ? props.scrollable : 'visible')};

  &:-webkit-scrollbar {
    display: none;
  }
`

const SidebarList = styled.div`
  background-color: transparent;
  height: calc(100vh - 116px);
  overflow-y: auto;
  box-sizing: border-box;

  border: 0;
  border-left-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.04);
`

const EmployeeProjectList: React.FC<EmployeeProjectListProps> = ({
  view,
  selectedProject,
  selectedEmployee,
  selectedMonth,
}) => {
  const navigate = useNavigate()

  if (!selectedEmployee) {
    return null
  }

  const MaybeRenderProjects = () => {
    if (!('planned_hours' in selectedEmployee)) {
      return <ProjectName>Loading...</ProjectName>
    }

    const leaveHours = sumHours(
      getEmployeeHoursByDetails(
        selectedEmployee.planned_hours,
        'leave',
        undefined,
        selectedMonth
      )
    )

    const billablePercentage = selectedEmployee.billable_percentage
      ? selectedEmployee.billable_percentage / 100
      : 1
    const leaveHoursBillable = billablePercentage * leaveHours

    return (
      <>
        {!selectedMonth || (
          <div>
            <ProjectName>Leave (only billable hours)</ProjectName>
            <ProjectDetails>
              <WorkHistoryIcon />
              <NumberLabel
                width={80}
                number={leaveHoursBillable}
                invertColor
                maxDec={2}
              />
            </ProjectDetails>
          </div>
        )}
        {selectedEmployee.projects
          ?.map((proj) => {
            const hoursProject = sumHours(
              getEmployeeHoursByDetails(
                selectedEmployee.planned_hours,
                undefined,
                proj,
                selectedMonth
              )
            )
            const tuple: [SimpleProject, number] = [proj, hoursProject]
            return tuple
          })
          .sort((a, b) => b[1] - a[1])
          .map((tuple) => {
            const proj = tuple[0]
            return (
              <div key={proj.id}>
                <ProjectName onClick={() => navigate('/project/' + proj.id)}>
                  {proj.name}
                </ProjectName>
                <ProjectDetails>
                  <WorkHistoryIcon />
                  <NumberLabel width={80} number={tuple[1]} invertColor />
                </ProjectDetails>
              </div>
            )
          })}
      </>
    )
  }

  const MaybeRenderMonths = () => {
    const nextYear = GetMonthsNextYear()

    if (!('planned_hours' in selectedEmployee)) {
      return <ProjectName></ProjectName>
    }

    if (!selectedEmployee.projects || selectedEmployee.projects.length === 0)
      return (
        <div>
          <ProjectName>Unplanned</ProjectName>
        </div>
      )
    return (
      <>
        {nextYear.map((monthYear) => {
          const planned_hours = sumHours(
            getEmployeeHoursByDetails(
              selectedEmployee.planned_hours,
              undefined,
              selectedProject,
              monthYear
            )
          )

          return (
            <div key={monthYear[0]}>
              <ProjectName>{monthYear[0]}</ProjectName>
              <ProjectDetails>
                <WorkHistoryIcon />
                <NumberLabel width={56} number={planned_hours} invertColor />
              </ProjectDetails>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <Container>
      <Table>
        <THead color="#B8D35B">
          <Cell hcenter="flex-start">
            <strong>Project details</strong>
          </Cell>
        </THead>
        <SidebarList>
          {view === 'projects' ? (
            <MaybeRenderMonths />
          ) : (
            <MaybeRenderProjects />
          )}
        </SidebarList>
      </Table>
    </Container>
  )
}

export default EmployeeProjectList
