import TeamPageDetails from './TeamPageDetails'
import Header from 'src/components/Header/Header'
import Main from 'src/components/Main/Main'
import { ApiConfig } from 'src/packages/api'
import styled from 'styled-components'
import { LoadingSkeleton } from '../project/[id]/components/LoadingSkeleton'
import { DetailedTeam } from 'src/packages/api/types/Project'
import { getMonthName, getMonthsForProjectPage } from '../helper'
import moment from 'moment'
import { useState } from 'react'
import Cell from 'src/components/Table/Cell'
import Table from 'src/components/Table/Table'
import TBody from 'src/components/Table/TBody'
import THead from 'src/components/Table/THead'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Small } from 'src/components/Typography/Typography'
import { saveAs } from 'file-saver'

const Container = styled(ScrollContainer)`
  height: calc(100vh - 58px);
  width: calc(100vw - 308px);
  cursor: grab;
`

export const TeamDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

interface TeamPageProps {
  config: ApiConfig
  team: DetailedTeam | null
}

export default function TeamPage({ team }: TeamPageProps) {
  const [monthOffset, setMonthoffset] = useState(0)
  const centerMonth = moment().add(1, 'M').add(monthOffset, 'M')
  const monthList = getMonthsForProjectPage(centerMonth)

  const hasEmployees =
    team && (team.employees?.length === 0 || team.employees === null)

  const MaybeRenderMonths = () => {
    if (hasEmployees) return null

    return (
      <>
        {monthList.map((m) => (
          <Cell
            width={110}
            key={getMonthName(m[0]) + m[1]}
            hcenter="center"
            direction="column"
          >
            <Small bold>{getMonthName(m[0])}</Small>
            <Small>{m[1]}</Small>
          </Cell>
        ))}
      </>
    )
  }

  if (!team) {
    return <LoadingSkeleton />
  }

  const convertDetailedTeamToCSV = (detailedTeam: DetailedTeam): string => {
    const columnDelimiter = ','
    const lineDelimiter = '\n'
    let result = ''

    // Header
    result +=
      'Employee ID' +
      columnDelimiter +
      'Employee Name' +
      columnDelimiter +
      'Function'

    // Add month-year pairs to the header
    monthList.forEach(([month, year]) => {
      result += `${columnDelimiter}Hours (${month}/${year})`
    })

    result += lineDelimiter

    // Rows
    detailedTeam.employees.forEach((employee) => {
      result += employee.id + columnDelimiter
      result += `"${employee.name}"` + columnDelimiter // Quotes are used in case names include commas
      result += (employee.function || '') + columnDelimiter

      // Process hours for each month-year pair
      monthList.forEach(([month, year]) => {
        // Filter matching entries and sum their hours
        const totalHoursForMonth =
          employee.planned_hours
            ?.filter((h) => h.month === month && h.year === year)
            .map((y) => {
              if (y.type === 'leave' && employee.billable_percentage) {
                return {
                  ...y,
                  hours: (y.hours * employee.billable_percentage) / 100,
                }
              }
              return y
            })
            .reduce((sum, record) => sum + record.hours, 0) || 0
        result += Math.round(totalHoursForMonth * 10) / 10 + columnDelimiter
      })

      result = result.replace(/,\s*$/, '') // Remove the last column delimiter from the end of the string
      result += lineDelimiter
    })

    return result
  }

  function downloadCSV(csv: string, filename: string): void {
    const csvFile = new Blob([csv], { type: 'text/csv' })
    saveAs(csvFile, filename)
  }

  function exportDetailedTeamToCSV(detailedTeam: DetailedTeam): void {
    const csv = convertDetailedTeamToCSV(detailedTeam)
    const filename = 'detailed_team_export.csv'
    downloadCSV(csv, filename)
  }

  const StartDownload = () => {
    return (
      <button onClick={() => exportDetailedTeamToCSV(team)}>
        Export to CSV
      </button>
    )
  }

  return (
    <>
      <Main>
        <Header title={team.name} options={<StartDownload />} />
        <Container ignoreElements="input">
          <Table>
            <THead color="#B8D35B" scrollableY="auto">
              <Cell width={200} vcenter="flex-start" direction="column">
                <strong>Team members</strong>
              </Cell>
              <Cell
                width={58}
                onClick={() => {
                  if (monthOffset <= 0) return
                  setMonthoffset(monthOffset - 1)
                }}
              >
                {monthOffset > 0 ? '<' : ''}
              </Cell>
              <MaybeRenderMonths />
              <Cell
                width={110}
                onClick={() => {
                  setMonthoffset(monthOffset + 1)
                }}
              >
                {'>'}
              </Cell>
            </THead>
            <TBody fit scrollableX="auto">
              <TeamPageDetails team={team} monthList={monthList} />
            </TBody>
          </Table>
        </Container>
      </Main>
    </>
  )
}
