import React from 'react'
import styled from 'styled-components'
import { TBodyProps, TBodyStylingProps } from './TBody.types'

const Container = styled.div<TBodyStylingProps>`
  background-color: ${(props) => (props.transparent ? 'transparent' : '#fff')};
  height: calc(100vh - 116px);
  width: ${(props) => (props.fit ? 'unset' : 'calc(100vw - 58px)')};
  overflow-y: ${(props) => (props.scrollableY ? props.scrollableY : 'visible')};
  overflow-x: ${(props) => (props.scrollableX ? props.scrollableX : 'visible')};

  &::-webkit-scrollbar {
    display: none;
  }
`

const TBody: React.FC<TBodyProps> = ({
  children,
  transparent,
  scrollableX,
  scrollableY,
  fit,
}) => {
  return (
    <Container
      transparent={transparent}
      scrollableX={scrollableX}
      scrollableY={scrollableY}
      fit={fit}
    >
      {children}
    </Container>
  )
}

export default TBody
