import useAuthorizedApiConfig from 'src/helpers/useAuthorizedApiConfig'
import Layout from 'src/layouts/Layout'
import TeamPage from './TeamPage'
import useTeam from 'src/data/useTeam'
import { useParams } from 'react-router-dom'

export default function Page() {
  const { id } = useParams()
  const team = useTeam(id)
  const { config } = useAuthorizedApiConfig()

  // forward data to client component
  return (
    <Layout>
      <TeamPage config={config} team={team} />
    </Layout>
  )
}
