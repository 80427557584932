import Cell from 'src/components/Table/Cell'
import Table from 'src/components/Table/Table'
import TBody from 'src/components/Table/TBody'
import THead from 'src/components/Table/THead'
import { Small } from 'src/components/Typography/Typography'
import { charCap } from 'src/helpers/charCap'
import { Employee } from 'src/packages/api/types/Project'
import styled from 'styled-components'
import { EmployeeState } from '../EmployeesPage'

interface EmployeeListProps {
  employees: Employee[]
  selectedEmployee: EmployeeState
  setCurrentEmployee: (emp: string) => void
}

const Container = styled.div`
  height: calc(100vh - 58px);
  width: 358px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

const EmployeeList: React.FC<EmployeeListProps> = ({
  employees,
  selectedEmployee,
  setCurrentEmployee,
}) => {
  return (
    <Container>
      <Table>
        <THead color="#B8D35B">
          <Cell hcenter="flex-start">
            <strong>Team members</strong>
          </Cell>
        </THead>
        <TBody scrollableY="auto">
          {employees.map((employee) => {
            const empId = selectedEmployee
              ? typeof selectedEmployee === 'string'
                ? selectedEmployee
                : selectedEmployee.id
              : null
            const selected = selectedEmployee ? employee.id === empId : false
            const employeeDescription = charCap(employee.function || '', 30)

            return (
              <Cell
                title={employee.name + ' - ' + employee.function}
                width={358}
                selected={selected}
                vcenter="flex-start"
                direction="column"
                onClick={() => setCurrentEmployee(employee.id)}
                key={employee.id}
              >
                <strong>{employee.name}</strong>
                <Small>{employeeDescription}</Small>
              </Cell>
            )
          })}
        </TBody>
      </Table>
    </Container>
  )
}

export default EmployeeList
