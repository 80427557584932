import theme from 'src/theme/theme'
import styled from 'styled-components'
import { MenuItemStylingProps } from './MainMenu.types'

export const Container = styled.div`
  width: 58px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #595959;
  color: #fff;
  border-radius: 2px;

  ul,
  li {
    margin: 0;
    padding: 0;
  }
`

export const MenuItem = styled.li<MenuItemStylingProps>`
  width: 58px;
  height: 58px;

  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;

  cursor: pointer;

  &:hover {
    ${(props) =>
      props.disabled
        ? `background-color: transparent; `
        : `background-color: ${theme.colors.highlight}`};
  }
`
