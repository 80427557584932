import { Button, Container, Label, Pin } from './Toggle.styles'
import { ToggleProps } from './Toggle.types'

const Toggle: React.FC<ToggleProps> = ({
  label,
  active,
  onClick,
  disabled,
}) => {
  const onClickHandler = () => {
    if (disabled) return
    if (!onClick) return
    onClick()
  }

  return (
    <Container onClick={onClickHandler} disabled={disabled}>
      <Label>{label}</Label>
      <Button active={active}>
        <Pin active={active} />
      </Button>
    </Container>
  )
}

export default Toggle
