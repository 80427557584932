import React from 'react'
import styled from 'styled-components'
import { THeadProps, THeadStylingProps } from './THead.types'

const Container = styled.div<THeadStylingProps>`
  display: flex;
  flex-direction: row;
  height: 58px;
  background-color: ${(props) => props.color};
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
`

const THead: React.FC<THeadProps> = ({ color, children }) => {
  return <Container color={color}>{children}</Container>
}

export default THead
