import React from 'react'
import theme from 'src/theme/theme'
import styled from 'styled-components'

interface NumberLabelProps {
  number?: number
  width?: number
  maxDec?: number
  invertColor?: boolean
}

interface LabelStylingProps {
  width?: number
  invertColor?: boolean
}

const Container = styled.div<LabelStylingProps>`
  padding: 4px 10px;
  width: ${(props) => (props.width ? `${props.width}px` : 'unset')};
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.invertColor ? 'rgba(25,46,5, 0.12)' : 'rgba(255,255,255, 0.12)'};
  color: ${(props) => (props.invertColor ? theme.colors.tertiary : '#fff')};
  border-radius: 2px;
  border: 1px dashed
    ${(props) => (props.invertColor ? theme.colors.tertiary : '#fff')};
  box-sizing: border-box;
`

const NumberLabel: React.FC<NumberLabelProps> = ({
  width,
  number,
  invertColor,
  maxDec,
}) => {
  return (
    <Container invertColor={invertColor} width={width}>
      {maxDec && number ? number?.toFixed(maxDec) : number}
    </Container>
  )
}

export default NumberLabel
