import Layout from 'src/layouts/Layout'
import Start from './Start'

export default function Page() {
  return (
    <Layout menu={false}>
      <Start />
    </Layout>
  )
}
