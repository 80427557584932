import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { ProjectsContextProvider } from './data/ProjectsContext'
import './index.css'
import LoginPage from './pages/auth/login/page'
import PasswordResetComplete from './pages/auth/password-reset/complete/page'
import PasswordResetStart from './pages/auth/password-reset/start/page'
import RegisterPage from './pages/auth/register/page'
import EmployeesPage from './pages/employees/page'
import ProjectPage from './pages/project/[id]/page'
import ProjectsPage from './pages/projects/page'
import TeamsPage from './pages/teams/page'
import TeamPage from './pages/team/page'
import config from './config'

const MainPage = () => {
  return <div />
}

const router = createBrowserRouter([
  {
    path: '/',
    loader: () => {
      return localStorage.getItem('token')
        ? redirect('/projects')
        : redirect('/login')
    },
    element: <MainPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/register',
    element: <RegisterPage />,
  },
  {
    path: '/password-reset/start',
    element: <PasswordResetStart />,
  },
  {
    path: '/password-reset/complete',
    element: <PasswordResetComplete />,
  },
  {
    path: '/projects',
    element: <ProjectsPage />,
  },
  {
    path: '/project/:id',
    element: <ProjectPage />,
  },
  {
    path: '/employees',
    element: <EmployeesPage />,
  },
  {
    path: '/teams',
    element: <TeamsPage />,
  },
  {
    path: '/team/:id',
    element: <TeamPage />,
  },
])

Sentry.init({
  dsn: config.sentryDsn,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ProjectsContextProvider>
      <RouterProvider router={router} />
    </ProjectsContextProvider>
  </React.StrictMode>
)
