import { Decoder } from 'decoders'
import { GetApiCall } from '../types'
import {
  generateApiHeaders,
  jsonCheck,
  throwIfResponseErrorFetch,
} from './helpers'

const generateGetApiCall =
  <Res>(
    endpoint: string,
    isAuthenticated: boolean,
    resDecoder?: Decoder<Res>
  ): GetApiCall<Res> =>
  async (apiConfig, endpointParam) => {
    // Check JWT and request body
    if (isAuthenticated && !apiConfig.jwt) {
      throw new Error('Endpoint requires JWT to be present in API config')
    }

    // Make API call
    const callUrl = `${apiConfig.baseUrl}/${endpoint}${
      endpointParam ? endpointParam : ''
    }`
    console.log(`[API] GET call to ${callUrl}`) // eslint-disable-line no-console
    const res = await fetch(callUrl, {
      headers: generateApiHeaders(apiConfig) as unknown as HeadersInit,
    })

    // Check if the request errored (non-2xx), if so, throw formatted error
    await throwIfResponseErrorFetch(res)

    // Validate response data type (successful response)
    const resData = (await res.json()) as Res
    if (resDecoder) {
      jsonCheck(resData, resDecoder)
    }

    // Everything is successfully validated
    return resData
  }

export default generateGetApiCall
