import React, { PropsWithChildren } from 'react'
import theme from 'src/theme/theme'
import styled from 'styled-components'
import { CellProps, CellStylingProps } from './Cell.types'

const Container = styled.div<CellStylingProps>`
  padding: 0 10px;
  height: 58px;
  width: 100%;
  min-width: ${(props) => (props.width ? `${props.width}px` : 'unset')};
  max-width: ${(props) => (props.width ? `${props.width}px` : 'unset')};
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  align-items: ${(props) => (props.vcenter ? props.vcenter : 'center')};
  justify-content: ${(props) => (props.hcenter ? props.hcenter : 'center')};
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};

  background-color: ${(props) =>
    props.selected ? theme.colors.secondary : 'transparent'};
  color: ${(props) => (props.selected ? '#fff' : 'inherit')};

  overflow: hidden;
`

const Cell: React.FC<PropsWithChildren<CellProps>> = ({
  title,
  selected,
  onClick,
  hcenter,
  vcenter,
  width,
  maxwidth,
  children,
  direction,
}) => {
  return (
    <Container
      title={title || undefined}
      selected={selected}
      clickable={onClick ? true : false}
      onClick={onClick}
      hcenter={hcenter}
      vcenter={vcenter}
      width={width}
      maxwidth={maxwidth}
      direction={direction}
    >
      {children}
    </Container>
  )
}

export default Cell

export const CellButton: React.FC<PropsWithChildren<CellProps>> = ({
  onClick,
  hcenter,
  vcenter,
  width,
  children,
}) => {
  return (
    <Button onClick={onClick} hcenter={hcenter} vcenter={vcenter} width={width}>
      {children}
    </Button>
  )
}

const Button = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.tertiary};
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #00aad4;
  }
`
