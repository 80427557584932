import Layout from 'src/layouts/Layout'
import Login from './Login'

export default function Page() {
  return (
    <Layout menu={false}>
      <Login />
    </Layout>
  )
}
