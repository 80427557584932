import styled from 'styled-components'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

export const Container = styled(Card)``

export const Actions = styled(CardActions)``

export const Content = styled(CardContent)``
