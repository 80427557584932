import moment from 'moment'
import NumberLabel from 'src/components/Label/NumberLabel'
import { Paragraph, Small } from 'src/components/Typography/Typography'
import theme from 'src/theme/theme'
import styled from 'styled-components'
import { MonthYearPair } from '../helper'

export const Container = styled.div<EmployeeMonthCardStyling>`
  padding: 10px;
  display: grid;
  grid-template-rows: 30px 68px;
  align-items: center;
  gap: 10px;
  background-color: ${(props) =>
    props.selected ? theme.colors.tertiary : '#fff'};
  color: ${(props) => (props.selected ? '#fff' : theme.colors.tertiary)};
  box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 20px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface EmployeeMonthCardStyling {
  selected?: boolean
}

export interface EmployeeMonthCardProps {
  selected?: boolean
  monthYear: MonthYearPair
  plannedHours: number
  availableHours: number
  setCurrentMonth: (month: MonthYearPair) => void
}

export const EmployeeMonthCard: React.FC<EmployeeMonthCardProps> = ({
  selected,
  monthYear,
  plannedHours,
  availableHours,
  setCurrentMonth,
}) => {
  return (
    <Container selected={selected} onClick={() => setCurrentMonth(monthYear)}>
      <Paragraph bold>
        {moment()
          .month(monthYear[0] - 1)
          .format('MMMM')}
      </Paragraph>
      <Content>
        <Column>
          <Small>Planned</Small>
          <NumberLabel
            number={plannedHours}
            invertColor={!selected}
            width={80}
            maxDec={2}
          />
        </Column>
        <Column>
          <Small>Available</Small>
          <NumberLabel
            number={availableHours}
            invertColor={!selected}
            width={80}
            maxDec={2}
          />
        </Column>
      </Content>
    </Container>
  )
}
