import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import theme from 'src/theme/theme'
import styled from 'styled-components'

interface HeaderProps {
  title: string
  options?: React.ReactNode
}

const Container = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary};
  color: #fff;
`

const BackButton = styled.div`
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary};
  color: #fff;
  cursor: pointer;

  &:visited {
    color: #fff;
  }

  &:hover {
    -webkit-filter: brightness(90%);
    transition: all 0.25s ease;
  }
`

const Title = styled.div`
  padding: 0 20px;
`

const Options = styled.div`
  margin-left: auto;
  margin-right: 12px;
`

const Header: React.FC<HeaderProps> = ({ title, options }) => {
  const navigate = useNavigate()
  return (
    <Container>
      <BackButton
        onClick={() => {
          navigate(-1)
        }}
      >
        <ArrowBackIcon />
      </BackButton>
      <Title>{title}</Title>
      <Options>{options}</Options>
    </Container>
  )
}

export default Header
