import Layout from 'src/layouts/Layout'
import Register from './Register'

export default function Page() {
  return (
    <Layout menu={false}>
      <Register />
    </Layout>
  )
}
