import TextField from '@mui/material/TextField'
import theme from 'src/theme/theme'
import styled from 'styled-components'
import { Label } from '../Typography/Typography'

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const errorColor = '#f00'

export const InputElement = styled(TextField)`
  .Mui-error {
    font-family: Roboto;
  }

  .MuiFormHelperText-root {
    color: ${theme.colors.error};
    font-size: 14px;
  }

  input:read-only,
  textarea:read-only,
  [contenteditable]:read-only {
    cursor: not-allowed;
    opacity: 1;
  }
`

export const NumberElement = styled.input.attrs({ type: 'number' })<{
  width?: number
  pastHours?: boolean
}>`
  padding: 0 14px;
  font-size: 14px;
  line-height: 1;

  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: 44px;
  color: ${(props) => (props.pastHours ? `#DDDDDD` : '#374951')};
  background-color: ${(props) => (props.pastHours ? `#192E37` : 'transparent')};

  border: 2px solid #374951;
  transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 4px;
  box-sizing: border-box;
  &:focus,
  &:active {
    outline: none;
  }
  &:placeholder {
    color: #bbb;
  }
`

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 4px;

  input {
    padding: 10.5px 14px;
  }
`

export const SearchInputelement = styled.input.attrs({ type: 'search' })<{
  width?: number
}>`
  font-size: 14px;
  line-height: 1;

  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: 44px;
  border: none;
  color: #374951;
  transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 4px;

  &:focus,
  &:active {
    outline: none;
  }
  &:placeholder {
    color: #bbb;
  }
`

export const LabelText = styled(Label)<{ hasError: boolean }>`
  margin-top: 20px;
  font-size: 18px;
  color: ${(props) => (props.hasError ? errorColor : '#333')};
`

export const ErrorText = styled(Label)`
  color: ${errorColor};
  font-size: 18px;
  padding: 0;
  margin: 0;
  height: 10px;
`
