import useEmployees from 'src/data/useEmployees'
import useProjects from 'src/data/useProjects'
import useAuthorizedApiConfig from 'src/helpers/useAuthorizedApiConfig'
import Layout from 'src/layouts/Layout'
import EmployeesPage from './EmployeesPage'

export default function Page() {
  const e = useEmployees()
  const p = useProjects()
  const { config } = useAuthorizedApiConfig()

  // forward data to client component
  return (
    <Layout>
      <EmployeesPage config={config} employees={e} projects={p} />
    </Layout>
  )
}
