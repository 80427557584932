import { useEffect, useState } from 'react'
import useAuthorizedApiConfig from 'src/helpers/useAuthorizedApiConfig'
import { getEmployees } from 'src/packages/api'
import { EmployeesResponse } from 'src/packages/api/types/Employee'

const useEmployees = (): EmployeesResponse | null => {
  const { config } = useAuthorizedApiConfig()
  const localEmployees = localStorage.getItem('employees')
  const parsedLocalEmployees = localEmployees
    ? JSON.parse(localEmployees)
    : null
  const [employees, setEmployees] = useState<EmployeesResponse | null>(
    parsedLocalEmployees
  )

  useEffect(() => {
    try {
      console.log('start fetching employees')
      getEmployees(config).then((res) => {
        setEmployees(res)
        localStorage.setItem('employees', JSON.stringify(res))
        console.log('fetch projects employees')
      })
    } catch (error) {
      console.log('Error trying to fetch projects with useEmployees hook')
      console.error(error)
    }
  }, [])

  return employees
}

export default useEmployees
