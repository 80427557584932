export enum GetEndpoints {
  Profile = 'user/profile',
  Projects = 'projects?status=active',
  Project = 'project/',
  Employees = 'employees',
  Employee = 'employee/',
  Teams = 'teams',
  Team = 'team/',
}

export enum PostEndpoints {
  CreateAccount = 'user/register',
  ValidateAccount = 'user/validate-account',
  Login = 'user/login',
  PassResetStart = 'user/password-reset/start',
  PassResetComplete = 'user/password-reset/complete',
  PlannedHours = 'project/hours',
}

export enum PutEndpoints {}

export enum DeleteEndpoints {}
