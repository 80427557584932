import React, { PropsWithChildren } from 'react'
import MainMenu from 'src/components/MainMenu/MainMenu'
import 'src/theme/global.css'
import theme from 'src/theme/theme'
import styled from 'styled-components'

const Main = styled.main`
  height: 100vh;
  width: calc(100vw - 58px);
  overflow: hidden;

  &:-webkit-scrollbar {
    display: none;
  }
`

const ContainerWithoutMenu = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  align-items: center;
  background-color: ${theme.colors.primary};
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 58px auto;
`

interface LayoutProps {
  menu?: boolean
}

const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  children,
  menu = true,
}) => {
  if (!menu) return <ContainerWithoutMenu>{children}</ContainerWithoutMenu>

  return (
    <Container>
      <MainMenu />
      <Main>{children}</Main>
    </Container>
  )
}

export default Layout
