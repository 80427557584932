import { useEffect, useState } from 'react'
import useAuthorizedApiConfig from 'src/helpers/useAuthorizedApiConfig'
import { getTeam } from 'src/packages/api'
import { DetailedTeam } from 'src/packages/api/types/Project'

const useTeam = (id?: string): DetailedTeam | null => {
  const { config } = useAuthorizedApiConfig()

  const [team, setTeam] = useState<DetailedTeam | null>(null)

  useEffect(() => {
    if (!id) return

    try {
      console.log('start fetching teams')
      getTeam(config, id).then((res) => {
        setTeam(res)
      })
    } catch (error) {
      console.log('Error trying to fetch team with useTeam hook')
      console.error(error)
    }
  }, [id])

  return team
}

export default useTeam
