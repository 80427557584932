import { MonthYearPair } from 'src/pages/helper'
import { DetailedEmployee } from 'src/packages/api/types/Employee'
import Cell from 'src/components/Table/Cell'
import { Container } from 'src/components/MonthList/MonthListHours'
import { NumberInput } from 'src/components/TextInput/TextInput'

interface TeamMonthListProps {
  monthList: MonthYearPair[]
  employee: DetailedEmployee
}

export const TeamMonthListHours: React.FC<TeamMonthListProps> = ({
  monthList,
  employee,
}) => {
  const billablePercentage = employee.billable_percentage
    ? employee.billable_percentage / 100
    : 1

  return (
    <Container months={monthList.length + 1} width={110}>
      {monthList.map((monthyear) => {
        const month = monthyear[0]
        const year = monthyear[1]
        const hours = employee.planned_hours || []
        const planned = hours
          .filter((x) => x.month === month && x.year === year)
          .reduce(
            (acc, curr) => {
              let newHours = curr.hours

              if (curr.type === 'leave') {
                // For leave, we want to show only the percentage of hours that are billable.
                newHours = billablePercentage * curr.hours
              }

              return {
                hours: acc.hours + newHours,
              }
            },
            {
              hours: 0,
            }
          )

        const roundedHours = Number(planned.hours.toFixed(1))

        return (
          <Cell key={month + ',' + year} width={110}>
            <NumberInput placeholder="Hours" value={roundedHours} disabled />
          </Cell>
        )
      })}
    </Container>
  )
}
