import Container from '@mui/material/Container'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { Logo } from 'src/assets/Logo'
import Button from 'src/components/Button/Button'
import Card from 'src/components/Card/Card'
import TextInput from 'src/components/TextInput/TextInput'
import { H2, Paragraph } from 'src/components/Typography/Typography'
import config from 'src/config'
import getApiErrorTitle from 'src/helpers/getApiErrorTitle'
import { passwordResetStart } from 'src/packages/api'
import { Content, Footer } from 'src/pages/auth/styling'
import * as Yup from 'yup'

const resetStartSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
})
type ResetValues = {
  email: string
}

export default function Start() {
  const [requestSent, setRequestSent] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')

  return (
    <Container maxWidth="xs">
      <Card>
        {requestSent ? (
          <>
            <Content>
              <Logo />
              <H2>Password has been reset</H2>
              <Paragraph>
                An email has been sent to the following email address: {email}
              </Paragraph>
              <Button
                title="Request again"
                variant="contained"
                onClick={() => setRequestSent(false)}
              />
            </Content>
            <Footer>
              <Button href="/login" title="Login" />
            </Footer>
          </>
        ) : (
          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={async (values: ResetValues, action) => {
              try {
                setEmail(values.email)
                await passwordResetStart(config, values)
                setRequestSent(true)
              } catch (e) {
                action.setErrors({ email: getApiErrorTitle(e) })
              }
            }}
            validationSchema={resetStartSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {(formik) => (
              <Form>
                <Content>
                  <Logo />
                  <TextInput
                    label="Email"
                    placeholder="email"
                    type="email"
                    validationError={
                      formik.errors.email ? formik.errors.email : undefined
                    }
                    value={formik.values.email}
                    onChange={formik.handleChange('email')}
                    variant="standard"
                  />
                </Content>
                <Footer>
                  <Button href="/login" title="Go back" />
                  <Button
                    title="reset password"
                    variant="contained"
                    onClick={formik.submitForm}
                  />
                </Footer>
              </Form>
            )}
          </Formik>
        )}
      </Card>
    </Container>
  )
}
