import Container from '@mui/material/Container'
import { Form, Formik } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Logo } from 'src/assets/Logo'
import Button from 'src/components/Button/Button'
import Card from 'src/components/Card/Card'
import TextInput from 'src/components/TextInput/TextInput'
import config from 'src/config'
import getApiErrorTitle from 'src/helpers/getApiErrorTitle'
import {
  passwordResetComplete,
  PasswordResetCompleteRequest,
} from 'src/packages/api'
import { Content, Footer } from 'src/pages/auth/styling'
import * as Yup from 'yup'

type ResetCompleteValues = {
  password: string
  resetToken?: string
}

const resetCompleteSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  resetToken: Yup.string().required('Token is required in url'),
})

export default function Complete() {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const token = params.get('token')

  return (
    <Container maxWidth="xs">
      <Card>
        <Formik
          initialValues={{
            password: '',
            resetToken: token || undefined,
          }}
          onSubmit={async (values: ResetCompleteValues, action) => {
            try {
              await passwordResetComplete(
                config,
                values as PasswordResetCompleteRequest
              )
              navigate('/login')
            } catch (e) {
              action.setErrors({
                resetToken: getApiErrorTitle(e),
              })
            }
          }}
          validationSchema={resetCompleteSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formik) => {
            const error = () => {
              if (formik.errors.password) return formik.errors.password
              if (formik.errors.resetToken) return formik.errors.resetToken
              return undefined
            }
            return (
              <Form>
                <Content>
                  <Logo />
                  <TextInput
                    onChange={formik.handleChange('password')}
                    value={formik.values.password}
                    label="new password"
                    placeholder="new password"
                    type="new-password"
                    validationError={error()}
                    variant={'standard'}
                  />
                </Content>
                <Footer>
                  <Button href="/login" title="Login" />
                  <Button
                    title="submit"
                    onClick={formik.submitForm}
                    variant="contained"
                  />
                </Footer>
              </Form>
            )
          }}
        </Formik>
      </Card>
    </Container>
  )
}
