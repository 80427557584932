import { useState } from 'react'
import { updatePlannedHours } from 'src/packages/api'
import { DetailedProject } from 'src/packages/api/types/Project'
import { MonthYearPair } from 'src/pages/helper'
import styled from 'styled-components'
import { ConfigProps } from '../ProjectPage'
import EmployeeRow from './EmployeeRow'

interface EmployeesProps extends ConfigProps {
  project: DetailedProject
  monthList: MonthYearPair[]
}

export const EmployeesList = styled.div`
  height: calc(100vh - 174px);
`

const Employees: React.FC<EmployeesProps> = ({
  project,
  monthList,
  config,
}) => {
  const employees = project.employees || []
  const [employeeDetails, setShowEmployeeDetails] = useState<string>('')

  const save = () => {
    const hours =
      project.employees?.flatMap(
        (employee) =>
          employee.planned_hours?.map((x) => ({
            ...x,
            employee_id: employee.id,
          })) || []
      ) || []
    const filteredHours = hours.filter(
      (x) => x.project_id === project.id && x.type === 'project'
    )

    try {
      updatePlannedHours(config, {
        data: filteredHours,
      })
    } catch (e) {
      console.error('Error updating planned hours')
      console.error(e)
      //TODO: show toast or something similar.
    }
  }

  if (employees.length === 0)
    return (
      <EmployeesList>
        {employees.map((employee) => {
          return (
            <EmployeeRow
              key={employee.id + employee.id + employee.name}
              project={project}
              monthList={monthList}
              employee={employee}
              onPressShowEmployeeDetails={setShowEmployeeDetails}
              showEmployeeDetails={employeeDetails}
              save={save}
            />
          )
        })}
      </EmployeesList>
    )

  return (
    <EmployeesList>
      {employees.map((employee) => {
        return (
          <EmployeeRow
            key={employee.id + employee.id + employee.name}
            project={project}
            monthList={monthList}
            employee={employee}
            onPressShowEmployeeDetails={setShowEmployeeDetails}
            showEmployeeDetails={employeeDetails}
            save={save}
          />
        )
      })}
    </EmployeesList>
  )
}
export default Employees
