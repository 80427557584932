import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TextField } from '@mui/material'

interface DatePickerProps {
  label: string
  value: string | null
  onChange: (date: string | null) => void
}

const DatePicker: React.FC<DatePickerProps> = ({ label, value, onChange }) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <DesktopDatePicker
      label={label}
      inputFormat="DD/MM/YYYY"
      value={value}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} />}
    />
  </LocalizationProvider>
)

export default DatePicker
