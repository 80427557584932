import { AxiosRequestHeaders, AxiosResponse } from 'axios'
import { guard, Decoder } from 'decoders'
import { apiErrorDecoder } from './decoders'
import { ApiError, ApiConfig } from '../types/index'
import GenesisApiError from './api-error'

export const generateApiHeaders = (config: ApiConfig): AxiosRequestHeaders => {
  if (config.jwt) {
    return {
      Authorization: `Bearer ${config.jwt}`,
    }
  }

  return {}
}

export const jsonCheck = <T>(data: T, decoder: Decoder<T>) => {
  const decodeChecker = guard(decoder)
  // TODO: Allow underscore to be not used
  // eslint-disable-next-line
  // @ts-ignore
  const _ = decodeChecker(data) // eslint-disable-line
}

export const throwIfResponseError = (res: AxiosResponse) => {
  if (res.status < 200 || res.status > 299) {
    const err = res.data as ApiError

    // eslint-disable-next-line no-console
    console.warn(err)

    jsonCheck(err, apiErrorDecoder)

    throw new GenesisApiError(err)
  }
}

export const throwIfResponseErrorFetch = async (res: Response) => {
  if (!res.ok) {
    const err = (await res.json()) as ApiError

    jsonCheck(err, apiErrorDecoder)

    throw new GenesisApiError(err)
  }
}
