import IconButton from '@mui/material/IconButton'
import moment from 'moment'
import React from 'react'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import DateProps from './Date.types'

const Date: React.FC<DateProps> = ({ date, title }) => {
  if (date === '0001-01-01T00:00:00Z')
    return (
      <IconButton title={title}>
        <EventBusyIcon />
      </IconButton>
    )

  return <>{moment(date).format('DD/MM/YYYY')}</>
}

export default Date
