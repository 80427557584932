import styled from 'styled-components'
import { SidebarStylingProps } from './Sidebar.types'

export const Container = styled.div<SidebarStylingProps>`
  height: calc(100vh - 58px);
  width: 358px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  position: ${(props) => (props.zindex === 0 ? 'relative' : 'absolute')};
  top: 58px;
  ${(props) => (props.side === 'left' ? 'left: 0;' : 'right: 0;')};
  z-index: ${(props) => (props.zindex ? props.zindex : '9999')};
  overflow: hidden;

  box-sizing: border-box;
  border: 0;
  border-left-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
`
