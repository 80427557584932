import Layout from 'src/layouts/Layout'
import Complete from './Complete'

export default function Page() {
  return (
    <Layout menu={false}>
      <Complete />
    </Layout>
  )
}
