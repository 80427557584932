import Avatar from 'src/components/Avatar/Avatar'
import Cell from 'src/components/Table/Cell'
import Table from 'src/components/Table/Table'
import TBody from 'src/components/Table/TBody'
import THead from 'src/components/Table/THead'
import { Paragraph, Small } from 'src/components/Typography/Typography'
import { DetailedEmployee } from 'src/packages/api/types/Employee'
import { Employee, Project } from 'src/packages/api/types/Project'
import {
  getEmployeeHoursByDetails,
  getNumberOfHoursAvailable,
  sumHours,
} from 'src/pages/helper'
import { LoadingSkeleton } from 'src/pages/project/[id]/components/LoadingSkeleton'
import theme from 'src/theme/theme'
import styled from 'styled-components'
import { CalenderView, EmployeeState } from '../EmployeesPage'
import { GetMonthsNextYear, GetMonthsThisYear, MonthYearPair } from '../helper'
import { EmployeeMonthCard } from './EmployeeMonthCard'

interface EmployeeCalendarProps {
  projects: Project[] | null
  selectedEmployee: EmployeeState
  selectedMonth?: MonthYearPair
  currentProject?: Project
  setCurrentMonth: (month: MonthYearPair) => void
  setCurrentProject: (project: Project) => void
  handleChangeView: () => void
  view: CalenderView
}

const EmployeeCalendar: React.FC<EmployeeCalendarProps> = ({
  projects,
  selectedEmployee,
  selectedMonth,
  setCurrentMonth,
}) => {
  if (!selectedEmployee) {
    return null
  }

  const thisYear = GetMonthsThisYear()
  const nextYear = GetMonthsNextYear()

  const MaybeRenderMonthly = () => {
    if (!projects) {
      return null
    }

    const selectedMonthWithFallback = selectedMonth || thisYear[0]

    return (
      <>
        <YearOverview
          year={thisYear[0][1]}
          monthList={thisYear}
          selectedMonth={selectedMonthWithFallback}
          setCurrentMonth={setCurrentMonth}
          selectedEmployee={selectedEmployee}
        />
        <YearOverview
          year={nextYear[0][1]}
          monthList={nextYear}
          selectedMonth={selectedMonthWithFallback}
          setCurrentMonth={setCurrentMonth}
          selectedEmployee={selectedEmployee}
        />
      </>
    )
  }

  return (
    <Table>
      <THead color="#B8D35B">
        <Cell hcenter="flex-start" vcenter="center">
          <div>
            <Avatar employee={selectedEmployee} />
          </div>
          <Description>
            <strong>{selectedEmployee.name}</strong>
            <Small>{selectedEmployee.function}</Small>
          </Description>
        </Cell>
      </THead>
      <TBody transparent fit scrollableY="auto">
        <MaybeRenderMonthly />
      </TBody>
    </Table>
  )
}
export default EmployeeCalendar

const Description = styled.div`
  margin-top: 2px;
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Content = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(3, 220px);
  gap: 10px;
  justify-content: center;

  @media only screen and (max-width: 1450px) {
    grid-template-columns: repeat(2, 220px);
  }

  @media only screen and (max-width: 1250px) {
    grid-template-columns: repeat(1, 220px);
  }
`

export const Date = styled.div`
  margin: 10px;
  padding: 2px 8px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-conten: center;
  background-color: ${theme.colors.primary};
  color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
`

interface YearOverviewProps {
  year: number
  monthList: MonthYearPair[]
  selectedMonth: MonthYearPair
  setCurrentMonth: (month: MonthYearPair) => void
  selectedEmployee: DetailedEmployee | Employee
}

const YearOverview: React.FC<YearOverviewProps> = ({
  year,
  monthList,
  selectedMonth,
  setCurrentMonth,
  selectedEmployee,
}) => {
  if (!('planned_hours' in selectedEmployee)) {
    return <LoadingSkeleton />
  }

  return (
    <>
      <Date>
        <Paragraph>{year}</Paragraph>
      </Date>
      <Content>
        {monthList.map((monthYear) => {
          const selected =
            selectedMonth &&
            monthYear[0] === selectedMonth[0] &&
            monthYear[1] === selectedMonth[1]

          const plannedHoursSum = sumHours(
            getEmployeeHoursByDetails(
              selectedEmployee.planned_hours,
              undefined,
              undefined,
              monthYear
            ).filter((x) => x.type === 'project')
          )

          const leaveHoursSum = sumHours(
            getEmployeeHoursByDetails(
              selectedEmployee.planned_hours,
              undefined,
              undefined,
              monthYear
            ).filter((x) => x.type === 'leave')
          )

          const billablePercentage = selectedEmployee.billable_percentage
            ? selectedEmployee.billable_percentage / 100
            : 1
          const totalHours =
            plannedHoursSum + billablePercentage * leaveHoursSum

          const available_hours = getNumberOfHoursAvailable(
            selectedEmployee,
            monthYear[0],
            monthYear[1],
            plannedHoursSum,
            leaveHoursSum
          )

          return (
            <EmployeeMonthCard
              key={monthYear[0]}
              selected={selected}
              monthYear={monthYear}
              plannedHours={totalHours}
              availableHours={available_hours}
              setCurrentMonth={setCurrentMonth}
            />
          )
        })}
      </Content>
    </>
  )
}
