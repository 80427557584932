const theme = {
  font: {
    headers: '',
    paragraph: '',
  },
  colors: {
    primary: '#95AF3D',
    secondary: '#00AAD4',
    tertiary: '#192E37',
    highlight: '#1475AB',
    success: '#95AF3D',
    warning: 'orange',
    error: 'red',
    background: {
      primary: '#FAFAFA',
    },
  },
}

export default theme
