import moment from 'moment'
import { useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import Header from 'src/components/Header/Header'
import Cell from 'src/components/Table/Cell'
import Table from 'src/components/Table/Table'
import TBody from 'src/components/Table/TBody'
import THead from 'src/components/Table/THead'
import { Small } from 'src/components/Typography/Typography'
import { ApiConfig } from 'src/packages/api'
import { DetailedProject } from 'src/packages/api/types/Project'
import { getMonthName, getMonthsForProjectPage } from 'src/pages/helper'
import styled from 'styled-components'
import { LoadingSkeleton } from './components/LoadingSkeleton'
import ProjectPageDetails from './ProjectPageDetails'

export interface ConfigProps {
  config: ApiConfig
}

interface ProjectProps extends ConfigProps {
  project: DetailedProject | null
}

const Container = styled(ScrollContainer)`
  height: calc(100vh - 58px);
  width: calc(100vw - 308px);
  cursor: grab;
`

const ProjectPage: React.FC<ProjectProps> = ({ project, config }) => {
  const [monthOffset, setMonthoffset] = useState(0)
  const centerMonth = moment().add(1, 'M').add(monthOffset, 'M')
  const monthList = getMonthsForProjectPage(centerMonth)

  const hasEmployees =
    project && (project.employees?.length === 0 || project.employees === null)

  const MaybeRenderMonths = () => {
    if (hasEmployees) return null
    return (
      <>
        <Cell width={110} hcenter="center" direction="column" key={'prev'}>
          <Small bold>{'Historical'}</Small>
          <Small>{'cumulative'}</Small>
        </Cell>
        {monthList.map((m) => (
          <Cell
            width={110}
            key={getMonthName(m[0]) + m[1]}
            hcenter="center"
            direction="column"
          >
            <Small bold>{getMonthName(m[0])}</Small>
            <Small>{m[1]}</Small>
          </Cell>
        ))}
      </>
    )
  }

  if (!project) {
    return <LoadingSkeleton />
  }

  return (
    <>
      <Header title={project.name} />
      <Container hideScrollbars={false} ignoreElements="input">
        <Table>
          <THead color="#B8D35B" scrollableY="auto">
            <Cell width={200} vcenter="flex-start" direction="column">
              <strong>Team members</strong>
            </Cell>
            <Cell
              width={58}
              onClick={() => {
                if (monthOffset <= 0) return
                setMonthoffset(monthOffset - 1)
              }}
            >
              {monthOffset > 0 ? (
                <>
                  <div style={{}}>
                    <LeftArrowIcon />
                  </div>
                </>
              ) : (
                ''
              )}
            </Cell>
            <MaybeRenderMonths />
            <Cell
              width={110}
              onClick={() => {
                setMonthoffset(monthOffset + 1)
              }}
            >
              <div style={{}}>
                <RightArrowIcon />
              </div>
            </Cell>
          </THead>
          <TBody fit scrollableX="auto">
            <ProjectPageDetails
              monthList={monthList}
              project={project}
              config={config}
            />
          </TBody>
        </Table>
      </Container>
    </>
  )
}
export default ProjectPage

const RightArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14M12 5l7 7-7 7" />
    </svg>
  )
}

const LeftArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 12H5m7 7l-7-7 7-7" />
    </svg>
  )
}
