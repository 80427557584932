import styled from 'styled-components'

// TODO: Move this to styling/theme package
const scalingFactor = 1.2
const baseSizePx = 18
const getFontSize = (scalingExponent: number): string => {
  const scalingNumber = scalingFactor ** scalingExponent
  const fontSize = baseSizePx * scalingNumber
  const fontSizeRounded = Math.round(fontSize)
  return `${fontSizeRounded}px`
}

export const H1 = styled.h1`
  font-size: ${getFontSize(4)};
  font-family: 'Roboto', sans-serif;
`

export const H2 = styled.h2`
  margin: 10px 0 10px;
  font-size: ${getFontSize(3)};
  font-family: 'Roboto', sans-serif;
`

export const H3 = styled.h3`
  font-size: ${getFontSize(2)};
  font-family: 'Roboto', sans-serif;
`

export const H4 = styled.h4`
  margin: 4px 0;
  font-size: ${getFontSize(1)};
  font-family: 'Roboto', sans-serif;
`

export const Paragraph = styled.p<{ bold?: boolean; italic?: boolean }>`
  margin: 5px 0;
  font-size: ${getFontSize(0)};
  font-family: 'Roboto', sans-serif;
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
`

export const Small = styled.small<{ bold?: boolean }>`
  margin: 0 0 4px;
  font-size: ${getFontSize(-1)};
  font-family: 'Roboto', sans-serif;
  font-weight: ${(props) => (props.bold ? '800' : '400')};
  word-break: break-all;
`

export const LinkText = styled(Paragraph)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const Label = styled.label`
  font-size: ${getFontSize(-1)};
`
