import Container from '@mui/material/Container'
import { Form, Formik } from 'formik'
import { Logo } from 'src/assets/Logo'
import Button from 'src/components/Button/Button'
import Card from 'src/components/Card/Card'
import TextInput from 'src/components/TextInput/TextInput'
import config from 'src/config'
import getApiErrorTitle from 'src/helpers/getApiErrorTitle'
import { register } from 'src/packages/api'
import * as Yup from 'yup'
import { Content, Footer } from '../styling'
import { useState } from 'react'

type RegisterValues = {
  email: string
  password: string
  firstName: string
}

const registerSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  firstName: Yup.string().required('First name is required'),
})

export default function Register() {
  const initialValues = {
    email: '',
    password: '',
    firstName: '',
  }
  const [madeRegistration, setMadeRegistration] = useState(false)

  return (
    <Container maxWidth="xs">
      <Card>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values: RegisterValues, action) => {
            setMadeRegistration(false)
            try {
              await register(config, values)
              setMadeRegistration(true)
              action.resetForm()
            } catch (e) {
              action.setErrors({ email: getApiErrorTitle(e) })
            }
          }}
          validationSchema={registerSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formik) => (
            <Form>
              <Content>
                <Logo />
                <TextInput
                  label="Name"
                  type="text"
                  validationError={
                    formik.errors.firstName && formik.touched.firstName
                      ? formik.errors.firstName
                      : undefined
                  }
                  value={formik.values.firstName}
                  onChange={formik.handleChange('firstName')}
                  variant="standard"
                />
                <TextInput
                  label="Email"
                  placeholder="email"
                  type="email"
                  validationError={
                    formik.errors.email ? formik.errors.email : undefined
                  }
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  variant="standard"
                />
                <TextInput
                  label="Password"
                  type="password"
                  validationError={
                    formik.errors.password ? formik.errors.password : undefined
                  }
                  value={formik.values.password}
                  onChange={formik.handleChange('password')}
                  variant="standard"
                />
              </Content>
              <Footer>
                <Button href="/login" title="Login" />
                <Button
                  title="register"
                  variant="contained"
                  onClick={formik.submitForm}
                />
              </Footer>
              {!madeRegistration ||
                'Account created! See the confirmation email for login instructions'}
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  )
}
