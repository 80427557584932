import { useEffect, useState } from 'react'
import useAuthorizedApiConfig from 'src/helpers/useAuthorizedApiConfig'
import { getTeams } from 'src/packages/api'
import { TeamsResponse } from 'src/packages/api/types/Project'

const useTeams = (): TeamsResponse | null => {
  const { config } = useAuthorizedApiConfig()

  const [teams, setTeams] = useState<TeamsResponse | null>(null)

  useEffect(() => {
    try {
      console.log('start fetching teams')
      getTeams(config).then((res) => {
        setTeams(res)
      })
    } catch (error) {
      console.log('Error trying to fetch teams with useTeams hook')
      console.error(error)
    }
  }, [])

  return teams
}

export default useTeams
