import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Face6OutlinedIcon from '@mui/icons-material/Face6Outlined'
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Logo } from 'src/assets/Logo'
import styled from 'styled-components'
import { Container, MenuItem } from './MainMenu.styles'
import { People } from '@mui/icons-material'

const StyledLogo = styled(Logo)`
  width: 48px;
  height: 15px;
`

const MainMenu: React.FC = () => {
  const navigate = useNavigate()

  const pages = [
    {
      url: '/projects',
      icon: <TableChartOutlinedIcon />,
    },
    {
      url: '/employees',
      icon: <Face6OutlinedIcon />,
    },
    {
      url: '/teams',
      icon: <People />,
    },
  ]

  return (
    <Container>
      <ul>
        <MenuItem
          disabled
          onClick={() => {
            navigate('/projects')
          }}
        >
          <StyledLogo />
        </MenuItem>
        {pages.map((page) => (
          <MenuItem
            onClick={() => {
              navigate(page.url)
            }}
            key={page.url}
            isCurrentPage={false}
          >
            {page.icon}
          </MenuItem>
        ))}
      </ul>
      <MenuItem
        title="Sign out"
        onClick={() => {
          localStorage.clear()
          navigate('/login')
        }}
      >
        <ExitToAppIcon />
      </MenuItem>
    </Container>
  )
}

export default MainMenu
