import moment, { Moment } from 'moment'
import { DetailedEmployee } from 'src/packages/api/types/Employee'
import {
  DetailedProject,
  Employee,
  PlannedHours,
  PlannedHoursList,
  Project,
  SimpleProject,
} from 'src/packages/api/types/Project'

export type MonthYearPair = [month: number, year: number]

type GetMonthsForProject = (project: DetailedProject) => MonthYearPair[]
export const getMonthsForProject: GetMonthsForProject = (project) => {
  const endDate =
    project.end_date !== '0001-01-01T00:00:00Z'
      ? moment(project.end_date)
      : moment().add(6, 'M') // Falls back to 3 months after the current date.
  const startDate =
    project.start_date !== '0001-01-01T00:00:00Z'
      ? moment(project.start_date)
      : endDate.clone().add(-12, 'M') // Falls back to 6 months before the end date.

  const dates = []

  for (let year = startDate.year(); year <= endDate.year(); year++) {
    const endMonth = year != endDate.year() ? 11 : endDate.month()
    const startMonth = year === startDate.year() ? startDate.month() : 0
    for (
      let currentMonth = startMonth;
      currentMonth <= endMonth;
      currentMonth =
        currentMonth > 12 ? currentMonth % 12 || 11 : currentMonth + 1
    ) {
      const month = currentMonth + 1
      const date: MonthYearPair = [month, year]
      dates.push(date)
    }
  }

  return dates
}

type futurePH = {
  hours_spent: number
  hours_planned: number
  value_spent: number
  value_planned: number
}

export const getFuturePHValuesByEmp = (
  emp: DetailedEmployee,
  projID?: string
): futurePH => {
  const planned_hours = (emp.planned_hours || []).reduce((sum, ph) => {
    if (ph.type != 'project') return sum
    if (projID && ph.project_id != projID) return sum

    return ph.type == 'project' ? sum + ph.hours : sum
  }, 0)

  return {
    hours_spent: emp.spent_hours_future,
    hours_planned: planned_hours,
    value_spent: emp.spent_hours_future * emp.tariff,
    value_planned: planned_hours * emp.tariff,
  }
}

export const getFuturePHValuesByProj = (proj: DetailedProject) => {
  const futurePHList =
    proj.employees?.map((emp) => getFuturePHValuesByEmp(emp, proj.id)) || []
  return futurePHList.reduce(
    (sum, current) => {
      return {
        hours_spent: sum.hours_spent + current.hours_spent,
        hours_planned: sum.hours_planned + current.hours_planned,
        value_spent: sum.value_spent + current.value_spent,
        value_planned: sum.value_planned + current.value_planned,
      }
    },
    {
      hours_spent: 0,
      hours_planned: 0,
      value_spent: 0,
      value_planned: 0,
    }
  )
}

export const getMonthsForProjectPage = (centerMonth: Moment) => {
  const dates = []
  const startDate = centerMonth.clone().add(-1, 'M')
  const endDate = centerMonth.clone().add(4, 'M')

  for (let year = startDate.year(); year <= endDate.year(); year++) {
    const endMonth = year != endDate.year() ? 11 : endDate.month()
    const startMonth = year === startDate.year() ? startDate.month() : 0
    for (
      let currentMonth = startMonth;
      currentMonth <= endMonth;
      currentMonth =
        currentMonth > 12 ? currentMonth % 12 || 11 : currentMonth + 1
    ) {
      const month = currentMonth + 1
      const date: MonthYearPair = [month, year]
      dates.push(date)
    }
  }
  return dates
}

export const getEmployeeHoursByDetails = (
  plannedHours: PlannedHoursList | null,
  phType?: string,
  project?: SimpleProject,
  month?: MonthYearPair
) => {
  if (!plannedHours) return []
  return plannedHours.filter((ph) => {
    if (phType && ph.type !== phType) return false
    if (project && ph.project_id !== project.id) return false
    if (month && (ph.month !== month[0] || ph.year !== month[1])) return false

    return true
  })
}

export const sumHours = (hours?: PlannedHours[]) => {
  if (!hours) return 0
  return hours.reduce((acc, ph) => acc + ph.hours, 0)
}

export const getNumberOfHoursAvailable = (
  employee: DetailedEmployee,
  month: number,
  year: number,
  plannedHours?: number,
  leaveHours?: number
) => {
  const workDays = getNumberOfWorkDays(month, year)
  const workHoursFulltime = workDays * 8
  const fulltimePercentage = employee.hours_per_week / 40
  const hoursAvailableBeforePlannedProjectHours =
    Math.floor(workHoursFulltime * fulltimePercentage) - (leaveHours || 0)
  const billableHours = employee.billable_percentage
    ? (hoursAvailableBeforePlannedProjectHours * employee.billable_percentage) /
      100
    : 0
  return billableHours - (plannedHours || 0)
}

export const getNumberOfWorkDays = (month: number, year: number) => {
  const days = daysInMonth(month, year)
  let weekdays = 0
  for (let i = 0; i < days; i++) {
    if (isWeekday(year, month, i + 1)) weekdays++
  }
  return weekdays
}

const daysInMonth = (month: number, year: number) => {
  return 32 - new Date(year, month, 32).getDate()
}

const isWeekday = (year: number, month: number, day: number) => {
  const date = new Date(year, month, day).getDay()
  return date != 0 && date != 6
}

export const getMonthName = (monthNumber: number) => {
  const date = new Date(2020, monthNumber - 1)
  return date.toLocaleString('en-US', { month: 'long' })
}

export const dateBoundedRB = (date: MonthYearPair, dateRB: MonthYearPair) => {
  // not tested
  if (date[1] < dateRB[1]) return true
  if (date[1] > dateRB[1]) return false
  return date[0] <= dateRB[0]
}

export const dateBetween = (
  date: MonthYearPair,
  dateLB?: MonthYearPair,
  dateRB?: MonthYearPair
) => {
  // not tested
  if (dateLB && dateBoundedRB(date, dateLB)) return false
  if (dateRB && dateBoundedRB(dateRB, date)) return false
  return true
}

export const dateEqual = (date: MonthYearPair, dateOther: MonthYearPair) => {
  return date[0] === dateOther[0] && date[1] === dateOther[1]
}

export const stringToDateTuple = (str: string) => {
  const date: MonthYearPair = [moment(str).month(), moment(str).year()]
  return date
}

export const getMonthBetween = (
  dateLB: MonthYearPair,
  dateRB: MonthYearPair
) => {
  const dates = []

  for (let year = dateLB[1]; year <= dateRB[1]; year++) {
    const endMonth = year != dateRB[1] ? 11 : dateRB[0]
    const startMonth = year === dateLB[1] ? dateLB[0] : 0
    for (
      let currentMonth = startMonth;
      currentMonth <= endMonth;
      currentMonth =
        currentMonth > 12 ? currentMonth % 12 || 11 : currentMonth + 1
    ) {
      const month = currentMonth + 1
      const date: MonthYearPair = [month, year]
      dates.push(date)
    }
  }

  return dates
}

export const getCurrentDate = () => {
  // not tested
  const curDate = new Date()
  return [curDate.getMonth() - 1, curDate.getFullYear()]
}

export const getEmployeesByDetails = (
  employees: Employee[],
  nameSubstring?: string,
  functionName?: string,
  team?: string
) => {
  return employees.filter((emp) => {
    // If name filter is on, but the employee does not fit the name, filter them.
    if (
      nameSubstring &&
      !emp.name.toLowerCase().includes(nameSubstring.toLowerCase())
    )
      return false

    // If function filter is on, but the employee does not have a function, filter them.
    if (functionName) {
      if (!emp.function) return false

      // If function filter is on, but the employee does not fit the function, filter them.
      if (!emp.function.toLowerCase().includes(functionName.toLowerCase()))
        return false
    }

    // If team filter is on, but the employee does not have a team, filter them.
    if (team && !emp.teams?.find((x) => x.id === team)) return false

    // If all filters are met, add the employee.
    return true
  })
}

export const parse_date = (dateStr: string) => {
  const date = new Date(dateStr)
  date.setHours(0, 0, 0, 0)
  return date
}

export const getProjectsByDetails = (
  projects: Project[],
  nameSubs?: string,
  startDate?: string,
  endDate?: string,
  projectManagerID?: string,
  region?: string
) => {
  return projects.filter((project) => {
    if (
      nameSubs &&
      !project.name.toLowerCase().includes(nameSubs.toLocaleLowerCase())
    )
      return false
    if (startDate && parse_date(project.start_date) < parse_date(startDate))
      return false
    if (endDate && parse_date(project.end_date) > parse_date(endDate))
      return false
    if (projectManagerID && project.project_manager.id !== projectManagerID)
      return false
    if (region && project.my_organization_profile?.organization.id != region)
      return false

    return true
  })
}
