import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import config from 'src/config'
import { getProject } from 'src/packages/api'
import { DetailedProject, PlannedHours } from 'src/packages/api/types/Project'

interface ProjectsContextData {
  isLoaded: boolean
  projectDetails: {
    [projectID: string]: DetailedProject
  }
  fetchProjectDetails: (id: string) => void
  updateHours: (id: string, newHours: PlannedHoursWithEmployee) => void
}

interface PlannedHoursWithEmployee extends PlannedHours {
  employee_id: string
}

function fetchProjectDetails() {
  // not used as of now
}

function updateHours() {
  // not used as of now
}

const initialValue: ProjectsContextData = {
  isLoaded: false,
  projectDetails: {},
  fetchProjectDetails: fetchProjectDetails,
  updateHours: updateHours,
}

const ProjectsContext = createContext<ProjectsContextData>(initialValue)

type ProjectsContextProviderProps = PropsWithChildren

export const ProjectsContextProvider: React.FC<
  ProjectsContextProviderProps
> = ({ children }) => {
  // Store the state (globally within the context)
  const [projectsState, setProjectsState] =
    useState<ProjectsContextData>(initialValue)

  // When context is mounted, look for local storage of the state and init it with those values.
  useEffect(() => {
    const localValue = localStorage.getItem('projectDetailsStateNew')
    if (localValue) {
      setProjectsState((oldState) => ({
        ...oldState,
        projectDetails: JSON.parse(localValue),
      }))
    }
  }, [])

  // Whenever the state changes, we store it to local storage.
  useEffect(() => {
    if (Object.keys(projectsState.projectDetails).length > 0) {
      localStorage.setItem(
        'projectDetailsStateNew',
        JSON.stringify(projectsState.projectDetails)
      )
    }
  }, [projectsState.projectDetails])

  // Whenever the state changes we reset the effects (this shouldnt be necessary? In other projects it's not...)
  useEffect(() => {
    setEffects() // Init the functions for the state.
  }, [projectsState.projectDetails])

  // Fetch the project details from the API and store it in the state.
  const fetchProjectDetails = (projectID: string) => {
    getProject(
      {
        ...config,
        jwt: localStorage.getItem('token') || '',
      },
      projectID
    ).then((res) => {
      setProjectsState((oldState) => ({
        ...oldState,
        projectDetails: {
          ...oldState.projectDetails,
          [projectID]: res,
        },
      }))
    })
  }

  // Update the hours in the local state. Note: does not update them to the server.
  const updateHours = (
    projectID: string,
    newHours: PlannedHoursWithEmployee
  ) => {
    const state = projectsState.projectDetails[projectID]
    if (!state || !state.employees) {
      return
    }

    const employee = state.employees.find((x) => x.id === newHours.employee_id)
    if (!employee) {
      return
    }

    const employees = state.employees || []

    const plannedHours = employee.planned_hours || []
    const plannedHoursForMonthExist =
      plannedHours &&
      plannedHours.find(
        (x) =>
          x.project_id === projectID &&
          x.month === newHours.month &&
          x.year === newHours.year
      )
    // If planned hours for month exist, update them
    if (plannedHoursForMonthExist) {
      // This is the new employee object with the updated hours
      const newEmployee = {
        ...employee,
        planned_hours: plannedHours.map((x) => {
          if (
            x.project_id === projectID &&
            x.month === newHours.month &&
            x.year === newHours.year
          ) {
            return newHours
          }
          return x
        }),
      }

      //In the state, we update the specific project
      setProjectsState((oldState) => ({
        ...oldState,
        projectDetails: {
          ...oldState.projectDetails,
          [projectID]: {
            ...oldState.projectDetails[projectID],
            // We look for the specific employee and update their hours
            employees: employees.map((x) => {
              if (x.id === newHours.employee_id) {
                return newEmployee
              }
              return x
            }),
          },
        },
      }))
      // We have updated the state and are done.
      return
    }

    // The plannedHours for this month and year for this employee did not exist, so we append them.
    const newEmployee = {
      ...employee,
      planned_hours: plannedHours.concat([newHours]),
    }

    setProjectsState((oldState) => ({
      ...oldState,
      projectDetails: {
        ...oldState.projectDetails,
        [projectID]: {
          ...oldState.projectDetails[projectID],
          employees: employees.map((x) => {
            if (x.id === newHours.employee_id) {
              return newEmployee
            }
            return x
          }),
        },
      },
    }))
  }

  const setEffects = () => {
    setProjectsState((currentState) => ({
      ...currentState,
      fetchProjectDetails: fetchProjectDetails,
      updateHours: updateHours,
      isLoaded: true,
    }))
  }

  return (
    <ProjectsContext.Provider value={projectsState}>
      {children}
    </ProjectsContext.Provider>
  )
}

export const useProjectsContext = () => {
  const projectsContext = useContext(ProjectsContext)
  return {
    isLoaded: projectsContext.isLoaded,
    projectDetails: projectsContext.projectDetails,
    fetchProjectDetails: projectsContext.fetchProjectDetails,
    updateHours: projectsContext.updateHours,
  }
}
