import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import React from 'react'
import Button from 'src/components/Button/Button'
import DatePicker from 'src/components/DatePicker/DatePicker'
import Sidebar from 'src/components/Sidebar/Sidebar'
import Cell from 'src/components/Table/Cell'
import Table from 'src/components/Table/Table'
import TBody from 'src/components/Table/TBody'
import THead from 'src/components/Table/THead'
import { Employee, Organization } from 'src/packages/api/types/Project'
import styled from 'styled-components'

interface FiltersProps {
  filter: FilterProps
  setFilters: (key: string, value: string | null) => void
  clear: () => void
  projectManagers: Employee[]
  projectRegions: Organization[]
}

export interface FilterProps {
  name: string
  start: string | null
  end: string | null
  projectLeader: string
  region: string
}

export const initialProjectFilter = {
  name: '',
  start: null,
  end: null,
  projectLeader: '',
  region: '',
}

const StyledFormControl = styled(FormControl)``

const Filters: React.FC<FiltersProps> = ({
  setFilters,
  filter,
  clear,
  projectManagers,
  projectRegions,
}) => {
  const projectManagerIds = projectManagers.map((pm) => pm.id)
  const projectRegionIds = projectRegions.map((pr) => pr.id)

  return (
    <Sidebar zindex={2}>
      <Table>
        <THead color="#B8D35B">
          <Cell hcenter="flex-start">
            <strong>Filter projects</strong>
          </Cell>
        </THead>
        <TBody fit>
          <StyledFormControl sx={{ m: 1, minWidth: 236 }}>
            <DatePicker
              label="Starts after"
              value={filter.start}
              onChange={(date: string | null) => setFilters('start', date)}
            />
          </StyledFormControl>
          <StyledFormControl sx={{ m: 1, minWidth: 236 }}>
            <DatePicker
              label="Ends before"
              value={filter.end}
              onChange={(date: string | null) => setFilters('end', date)}
            />
          </StyledFormControl>
          <StyledFormControl sx={{ m: 1, minWidth: 236 }}>
            <InputLabel id="project-filter">Project leader</InputLabel>
            <Select
              labelId="project-filter"
              id="project-filter"
              value={filter.projectLeader}
              label="project"
              onChange={(event: SelectChangeEvent) =>
                setFilters('projectLeader', event.target.value)
              }
            >
              <MenuItem value="">
                <em>Select Project Manager</em>
              </MenuItem>

              {projectManagers
                .filter((pm, pos) => {
                  return projectManagerIds.indexOf(pm.id) === pos
                })
                .sort((e1, e2) => (e1.name <= e2.name ? -1 : 1))
                .map((pm) => {
                  return (
                    <MenuItem key={pm.id} value={pm.id}>
                      {pm.name}
                    </MenuItem>
                  )
                })}
            </Select>
          </StyledFormControl>
          <StyledFormControl sx={{ m: 1, minWidth: 236 }}>
            <InputLabel id="region-filter">Region</InputLabel>
            <Select
              labelId="region-filter"
              id="region-filter"
              value={filter.region}
              label="Region"
              onChange={(event: SelectChangeEvent) =>
                setFilters('region', event.target.value)
              }
            >
              <MenuItem value="">
                <em>Select region</em>
              </MenuItem>

              {projectRegions
                .filter((pr, pos) => {
                  return projectRegionIds.indexOf(pr.id) === pos
                })
                .sort((pr1, pr2) => (pr1.name <= pr2.name ? -1 : 1))
                .map((pr) => {
                  return (
                    <MenuItem key={pr.id} value={pr.id}>
                      {pr.name}
                    </MenuItem>
                  )
                })
                .sort()}
            </Select>
          </StyledFormControl>
          <FormControl sx={{ m: 1, minWidth: 236 }}>
            <Button title={'Clear'} onClick={clear} />
          </FormControl>
        </TBody>
      </Table>
    </Sidebar>
  )
}

export default Filters
