import React from 'react'
import { DetailedEmployee } from 'src/packages/api/types/Employee'
import { Employee } from 'src/packages/api/types/Project'
import theme from 'src/theme/theme'
import styled from 'styled-components'

interface AvatarProps {
  employee: Employee | DetailedEmployee
}

interface AvatarStylingProps {
  bgImage?: string
}

const Container = styled.div<AvatarStylingProps>`
  margin-right: 4px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  ${(props) =>
    props.bgImage
      ? `background-image: url(${props.bgImage});`
      : `background-color: ${theme.colors.tertiary};`}
  background-repeat: no-repeat;
  background-size: 40px 40px;
  border: 2px solid #fff;
`

const Avatar: React.FC<AvatarProps> = ({ employee }) => {
  if ('avatar_small' in employee && employee.avatar_small) {
    return <Container bgImage={employee.avatar_small} />
  }
  return <Container>{employee.name.charAt(0)}</Container>
}

export default Avatar
