import ListItem from '@mui/material/ListItem'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Header from 'src/components/Header/Header'
import Main from 'src/components/Main/Main'
import Toggle from 'src/components/Toggle/Toggle'
import { ApiConfig, getEmployee } from 'src/packages/api'
import useLocalStorageState from 'use-local-storage-state'
import {
  DetailedEmployee,
  EmployeesResponse,
} from 'src/packages/api/types/Employee'
import {
  Employee,
  Project,
  ProjectsResponse,
  Team,
} from 'src/packages/api/types/Project'
import { getEmployeesByDetails } from 'src/pages/helper'
import styled from 'styled-components'
import { LoadingSkeleton } from '../project/[id]/components/LoadingSkeleton'
import EmployeeCalendar from './components/EmployeeCalendar'
import EmployeeList from './components/EmployeeList'
import EmployeeProjectList from './components/EmployeeProjectList'
import Filters, {
  FilterProps,
  initialProjectFilter,
} from './components/filters'
import { GetMonthsThisYear, MonthYearPair } from './helper'

export const Container = styled(ListItem)`
  display: flex;
  flex-direction: row;

  &:hover {
    background-color: #f2f2f2;
  }
`

export const EmployeesDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 358px 1fr 358px;
`

interface EmployeesPageProps {
  config: ApiConfig
  employees: EmployeesResponse | null
  projects: ProjectsResponse | null
}

export type CalenderView = 'month' | 'projects'
export type EmployeeLoading = Employee // if loading from /employee/:id
export type EmployeeState = null | EmployeeLoading | DetailedEmployee

export default function EmployeesPage({
  config,
  employees,
  projects,
}: EmployeesPageProps) {
  const [searchParams] = useSearchParams()
  const searchID = searchParams.get('id')
  const findEmployeeById =
    searchID &&
    employees?.find((employee: Employee) => employee.id === searchID)

  const employeeList = employees || []
  const sortedEmployeeList = employeeList
    .filter((x) => Boolean(x.name))
    .sort((a, b) => (a.name < b.name ? -1 : 1))

  // === States ===
  const [filter, setFilter] = useState<FilterProps>(initialProjectFilter)

  const [currentMonth, setCurrentMonth] = useState<MonthYearPair | undefined>(
    GetMonthsThisYear()[0]
  )
  const [currentProject, setCurrentProject] = useState<Project>()

  // === Filter Column ===

  const handleShowFilterColumn = () => {
    setShowFilterOptions(!showFilterOptions)
  }

  const handleClear = () => {
    setFilter(initialProjectFilter)
  }

  const filteredEmployees = getEmployeesByDetails(
    sortedEmployeeList,
    filter.name,
    filter.role,
    filter.team
  )

  const handleFilterValueUpdate = (key: string, value: string | null) => {
    setFilter({ ...filter, [key]: value })
  }

  // === View type (not used currently) ===
  const [view, setChangeView] = useState<CalenderView>('month')

  const handleChangeView = () => {
    return setChangeView('month')
  }

  // === Select current employee logic ===
  const [currentEmployee, setCurrentEmployee] =
    useLocalStorageState<EmployeeState>('selected-employee', {
      defaultValue: null,
    })

  const updateEmployeeWhenFetched = (employeeID: string) => {
    getEmployee(config, employeeID).then((newEmployee) => {
      setCurrentEmployee(newEmployee)
    })
  }

  const selectCurrentEmployee = () => {
    if (!sortedEmployeeList || sortedEmployeeList.length == 0) return null

    const loadingEmployee = findEmployeeById
      ? findEmployeeById
      : sortedEmployeeList[0]
    updateEmployeeWhenFetched(loadingEmployee.id)
    return loadingEmployee
  }

  useEffect(() => {
    if (!currentEmployee) {
      setCurrentEmployee(selectCurrentEmployee())
    }
  }, [employees])

  const setCurrentEmployeeWrapper = (employeeID: string) => {
    setCurrentMonth(undefined)
    const loadingEmployee: EmployeeState =
      employees?.find((emp) => emp.id === employeeID) || null
    setCurrentEmployee(loadingEmployee)
    updateEmployeeWhenFetched(employeeID)
  }

  const [showFilterOptions, setShowFilterOptions] = useState(false)

  if (!employees || !projects) {
    return <LoadingSkeleton />
  }

  const teams = employees
    .map((employee) => employee.teams)
    // List of teams flattened
    .flat()
    // Remove undefineds
    .filter(Boolean)
    // Remove duplicates
    .filter(
      (team, index, self) => self.findIndex((t) => t?.id === team?.id) === index
    ) as Team[]

  const sortedTeams = teams.sort((a, b) => (a.name < b.name ? -1 : 1))

  return (
    <>
      <Main>
        <Header
          title="Mobycon employees"
          options={
            <Toggle
              label="Filters"
              active={showFilterOptions}
              onClick={handleShowFilterColumn}
            />
          }
        />{' '}
        <EmployeesDetailsGrid>
          <EmployeeList
            employees={filteredEmployees}
            selectedEmployee={currentEmployee}
            setCurrentEmployee={setCurrentEmployeeWrapper}
          />
          <EmployeeCalendar
            projects={projects}
            selectedEmployee={currentEmployee}
            selectedMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            currentProject={currentProject}
            setCurrentProject={setCurrentProject}
            view={view}
            handleChangeView={handleChangeView}
          />
          <EmployeeProjectList
            view={view}
            selectedEmployee={currentEmployee}
            selectedProject={currentProject}
            selectedMonth={currentMonth}
            projects={projects}
          />
        </EmployeesDetailsGrid>
      </Main>
      {showFilterOptions && (
        <Filters
          clear={handleClear}
          filter={filter}
          setFilters={handleFilterValueUpdate}
          teams={sortedTeams}
        />
      )}
    </>
  )
}
