import { ApiError } from '../types/index'

class GenesisApiError extends Error {
  public err: ApiError

  constructor(err: ApiError) {
    super(`Error ${err.status}: ${err.title}`)

    this.err = err
  }
}

export default GenesisApiError
