import Container from '@mui/material/Container'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Logo } from 'src/assets/Logo'
import Button from 'src/components/Button/Button'
import Card from 'src/components/Card/Card'
import TextInput from 'src/components/TextInput/TextInput'
import config from 'src/config'
import { login, validateAccount } from 'src/packages/api'
import { Content, Footer } from 'src/pages/auth/styling'
import * as Yup from 'yup'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

type LoginValues = {
  email: string
  password: string
}

export default function Login() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const registrationToken = queryParams.get('token')

  const [succesful, setSuccessful] = useState<boolean | null>(null)

  useEffect(() => {
    async function validateRegistration() {
      if (registrationToken) {
        try {
          await validateAccount(config, {
            token: registrationToken,
          })
          setSuccessful(true)
        } catch (error) {
          setSuccessful(false)
          console.log(error)
        }
      }
    }

    validateRegistration()
  }, [registrationToken])

  function createValidationMessage() {
    if (!registrationToken) {
      return ''
    }
    if (succesful == null) {
      return 'validating account...'
    }
    if (succesful == false) {
      return 'could not validate account!'
    }
    return 'succesfully validated account! You can now login..'
  }

  return (
    <div>
      <Container maxWidth="xs">
        <Card>
          <Logo />
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={async (values: LoginValues) => {
              login(config, {
                email: values.email,
                password: values.password,
              }).then((res) => {
                localStorage.setItem('token', res.jwt)
                navigate('/projects')
              })
            }}
            validationSchema={loginSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {(formik) => (
              <Form>
                <Content>
                  {createValidationMessage()}
                  <TextInput
                    label="Email"
                    placeholder="email"
                    type="email"
                    validationError={
                      formik.errors.email ? formik.errors.email : undefined
                    }
                    value={formik.values.email}
                    onChange={formik.handleChange('email')}
                    variant="standard"
                  />
                  <TextInput
                    label="Password"
                    type="password"
                    validationError={
                      formik.errors.password
                        ? formik.errors.password
                        : undefined
                    }
                    value={formik.values.password}
                    onChange={formik.handleChange('password')}
                    variant="standard"
                  />
                  <Button
                    href="/password-reset/start"
                    title="Forgot Password?"
                  />
                </Content>

                <Footer>
                  <Button
                    title="login"
                    variant="contained"
                    onClick={formik.submitForm}
                  />
                </Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </div>
  )
}
