import useAuthorizedApiConfig from 'src/helpers/useAuthorizedApiConfig'
import Layout from 'src/layouts/Layout'
import TeamsPage from './TeamsPage'
import useTeams from 'src/data/useTeams'

export default function Page() {
  const teams = useTeams()
  const { config } = useAuthorizedApiConfig()

  // forward data to client component
  return (
    <Layout>
      <TeamsPage config={config} teams={teams} />
    </Layout>
  )
}
