import { useState } from 'react'
import Sidebar from 'src/components/Sidebar/Sidebar'
import { MonthYearPair } from 'src/pages/helper'
import { DetailedEmployee } from 'src/packages/api/types/Employee'
import {
  Column,
  Row,
  Container as RowContainer,
} from '../project/[id]/components/styles'
import Cell, { CellButton } from 'src/components/Table/Cell'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Small } from 'src/components/Typography/Typography'
import { HoursAvailable } from 'src/components/MonthList/MonthListHours'
import EmployeeDetailsOverlay from '../project/[id]/components/EmployeeDetailsOverlay'
import { TeamMonthListHours } from './TeamMonthListHours'

interface TeamEmployeeRowProps {
  monthList: MonthYearPair[]
  employee: DetailedEmployee
  onPressShowEmployeeDetails: (id: string) => void
  showEmployeeDetails: string
  isTotal?: boolean
}

export const TeamEmployeeRow: React.FC<TeamEmployeeRowProps> = ({
  monthList,
  employee,
  onPressShowEmployeeDetails,
  showEmployeeDetails,
  isTotal,
}) => {
  const [showHoursAvailable, setShowHoursAvailable] = useState(false)

  const handleShowDetails = () => {
    if (showEmployeeDetails === employee.id)
      return onPressShowEmployeeDetails('')
    return onPressShowEmployeeDetails(employee.id)
  }

  return (
    <Row>
      <RowContainer
        bgColor={isTotal ? '#B8D35B' : undefined}
        focus={showEmployeeDetails === employee.id}
      >
        <Cell
          width={200}
          vcenter="flex-start"
          direction="column"
          onClick={handleShowDetails}
        >
          <strong>{employee.name}</strong>
        </Cell>
        <CellButton
          width={58}
          onClick={() => {
            setShowHoursAvailable((oldState) => !oldState)
          }}
        >
          <AccessTimeIcon />
        </CellButton>
        <div>
          <TeamMonthListHours monthList={monthList} employee={employee} />
        </div>
      </RowContainer>
      {showHoursAvailable && (
        <RowContainer bgColor="#F2F2F2">
          <Cell hcenter="flex-start" onClick={handleShowDetails} width={358}>
            <Column>
              <strong>Hours available</strong>
              <Small>Hours to schedule</Small>
            </Column>
          </Cell>
          <Cell width={58} />
          <HoursAvailable monthList={monthList} employee={employee} />
        </RowContainer>
      )}
      {showEmployeeDetails === employee.id && (
        <Sidebar zindex={9}>
          <EmployeeDetailsOverlay employee={employee} />
        </Sidebar>
      )}
    </Row>
  )
}
