import ListItem from '@mui/material/ListItem'
import Header from 'src/components/Header/Header'
import Main from 'src/components/Main/Main'
import { ApiConfig } from 'src/packages/api'
import styled from 'styled-components'
import { LoadingSkeleton } from '../project/[id]/components/LoadingSkeleton'
import { TeamsResponse } from 'src/packages/api/types/Project'
import { useNavigate } from 'react-router-dom'

export const Container = styled(ListItem)`
  display: flex;
  flex-direction: row;

  &:hover {
    background-color: #f2f2f2;
  }

  height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
`

export const TeamDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

interface TeamsPageProps {
  config: ApiConfig
  teams: TeamsResponse
}

export default function TeamsPage({ teams }: TeamsPageProps) {
  const navigate = useNavigate()
  if (!teams) {
    return <LoadingSkeleton />
  }

  return (
    <>
      <Main>
        <Header title="Mobycon teams" />{' '}
        <TeamDetailsGrid>
          {teams.map((team) => {
            return (
              <Container
                onClick={() => {
                  navigate('/team/' + team.id)
                }}
                key={team.id}
              >
                {team.name}
              </Container>
            )
          })}
        </TeamDetailsGrid>
      </Main>
    </>
  )
}
