/* eslint-disable */
import {
    detailedEmployeeDecoder,
    detailedProjectDecoder,
    detailedTeamDecoder,
    employeesResponseDecoder,
    generateGetApiCall,
    generatePostApiCall,
    GetEndpoints,
    jwtResponseDecoder,
    loginRequestDecoder,
    passwordResetCompleteRequestDecoder,
    passwordResetStartRequestDecoder,
    PostEndpoints,
    profileResponseDecoder,
    projectsResponseDecoder,
    registerRequestDecoder,
    teamsResponseDecoder,
    updateHoursRequestDecoder,
    validateAccountRequestDecoder
} from './internal'
import { DetailedEmployee, EmployeesResponse } from './types/Employee'
import {
    JwtResponse,
    LoginRequest,
    PasswordResetCompleteRequest,
    PasswordResetStartRequest
} from './types/index'
import { ProfileResponse } from './types/Profile'
import {
    DetailedProject,
    DetailedTeam,
    ProjectsResponse,
    TeamsResponse,
    UpdateHoursRequest
} from './types/Project'

export const getProject = generateGetApiCall<DetailedProject>(
    GetEndpoints.Project,
    true,
    detailedProjectDecoder
)

export const getEmployees = generateGetApiCall<EmployeesResponse>(
    GetEndpoints.Employees,
    true,
    employeesResponseDecoder
)

export const getEmployee = generateGetApiCall<DetailedEmployee>(
    GetEndpoints.Employee,
    true,
    detailedEmployeeDecoder
)

export const getTeam = generateGetApiCall<DetailedTeam>(
    GetEndpoints.Team,
    true,
    detailedTeamDecoder
)

export const getTeams = generateGetApiCall<TeamsResponse>(
    GetEndpoints.Teams,
    true,
    teamsResponseDecoder
)

export const getProjects = generateGetApiCall<ProjectsResponse>(
    GetEndpoints.Projects,
    true,
    projectsResponseDecoder
)

export const getProfile = generateGetApiCall<ProfileResponse>(
    GetEndpoints.Profile,
    true,
    profileResponseDecoder
)

export const updatePlannedHours = generatePostApiCall<
    UpdateHoursRequest,
    DetailedProject
>(PostEndpoints.PlannedHours, true, updateHoursRequestDecoder)

export const register = generatePostApiCall(
    PostEndpoints.CreateAccount,
    false,
    registerRequestDecoder
)

export const validateAccount = generatePostApiCall(
    PostEndpoints.ValidateAccount,
    false,
    validateAccountRequestDecoder
)

export const login = generatePostApiCall<LoginRequest, JwtResponse>(
    PostEndpoints.Login,
    false,
    loginRequestDecoder,
    jwtResponseDecoder
)

export const passwordResetStart = generatePostApiCall<
    PasswordResetStartRequest,
    null
>(PostEndpoints.PassResetStart, false, passwordResetStartRequestDecoder)

export const passwordResetComplete = generatePostApiCall<
    PasswordResetCompleteRequest,
    JwtResponse
>(
    PostEndpoints.PassResetComplete,
    false,
    passwordResetCompleteRequestDecoder,
    jwtResponseDecoder
)

export * from './internal'
export * from './types'
export * from './types/index'

