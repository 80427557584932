import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useState } from 'react'
import MonthListHours, {
  HoursAvailable,
} from 'src/components/MonthList/MonthListHours'
import Sidebar from 'src/components/Sidebar/Sidebar'
import Cell, { CellButton } from 'src/components/Table/Cell'
import { Small } from 'src/components/Typography/Typography'
import { DetailedEmployee } from 'src/packages/api/types/Employee'
import { DetailedProject } from 'src/packages/api/types/Project'
import { MonthYearPair } from 'src/pages/helper'
import styled from 'styled-components'
import EmployeeDetailsOverlay from './EmployeeDetailsOverlay'
import { Column, Container } from './styles'

interface EmployeeRowProps {
  project: DetailedProject
  employee: DetailedEmployee
  monthList: MonthYearPair[]
  onPressShowEmployeeDetails: (value: string) => void
  showEmployeeDetails: string
  save: () => void
}

const Row = styled.div`
  width: 100%;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
`

const EmployeeRow: React.FC<EmployeeRowProps> = ({
  project,
  monthList,
  employee,
  onPressShowEmployeeDetails,
  showEmployeeDetails,
  save,
}) => {
  const [showHoursAvailable, setShowHoursAvailable] = useState(false)

  const handleShowDetails = () => {
    if (showEmployeeDetails === employee.id)
      return onPressShowEmployeeDetails('')
    return onPressShowEmployeeDetails(employee.id)
  }

  return (
    <Row>
      <Container focus={showEmployeeDetails === employee.id}>
        <Cell
          width={200}
          vcenter="flex-start"
          direction="column"
          onClick={handleShowDetails}
        >
          <strong>{employee.name}</strong>
          <Small>{employee.tariff.toFixed(1) + '€/h'}</Small>
        </Cell>
        <CellButton
          width={58}
          onClick={() => {
            setShowHoursAvailable((oldState) => !oldState)
          }}
        >
          <AccessTimeIcon />
        </CellButton>
        <div>
          <MonthListHours
            save={save}
            project={project}
            monthList={monthList}
            employee={employee}
          />
        </div>
      </Container>
      {showHoursAvailable && (
        <Container bgColor="#F2F2F2">
          <Cell hcenter="flex-start" onClick={handleShowDetails} width={358}>
            <Column>
              <strong>Hours available</strong>
              <Small>Hours to schedule</Small>
            </Column>
          </Cell>
          <Cell width={58} />
          <HoursAvailable
            shiftRight
            monthList={monthList}
            employee={employee}
          />
        </Container>
      )}
      {showEmployeeDetails === employee.id && (
        <Sidebar zindex={9}>
          <EmployeeDetailsOverlay employee={employee} />
        </Sidebar>
      )}
    </Row>
  )
}

export default EmployeeRow
