import React from 'react'
import { Actions, Container, Content } from './Card.styles'
import { CardProps } from './Card.types'

const Card: React.FC<CardProps> = ({ children, actions }) => (
  <Container>
    <Content>{children}</Content>
    {actions && <Actions>{actions}</Actions>}
  </Container>
)

export default Card
