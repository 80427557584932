import { useState } from 'react'
import { DetailedTeam } from 'src/packages/api/types/Project'
import { MonthYearPair } from 'src/pages/helper'
import { EmployeesList } from '../project/[id]/components/Employees'
import { TeamEmployeeRow } from './TeamEmployeeRow'

interface TeamEmployeesProps {
  team: DetailedTeam
  monthList: MonthYearPair[]
}

export const TeamEmployees: React.FC<TeamEmployeesProps> = ({
  team,
  monthList,
}) => {
  const employees = team.employees || []
  const [employeeDetails, setShowEmployeeDetails] = useState<string>('')

  if (employees.length === 0)
    return <EmployeesList>No employees found for this team.</EmployeesList>

  return (
    <EmployeesList>
      {employees.map((employee) => {
        return (
          <TeamEmployeeRow
            key={employee.id + employee.id + employee.name}
            monthList={monthList}
            employee={employee}
            onPressShowEmployeeDetails={setShowEmployeeDetails}
            showEmployeeDetails={employeeDetails}
          />
        )
      })}
      <Totals team={team} monthList={monthList} />
    </EmployeesList>
  )
}

const Totals: React.FC<TeamEmployeesProps> = ({ team, monthList }) => {
  const planned_hours = team.employees.flatMap(
    (x) =>
      x.planned_hours?.map((y) => {
        const billablePercentage = x.billable_percentage
          ? x.billable_percentage / 100
          : 1

        if (y.type === 'leave') {
          return {
            ...y,
            hours: y.hours * billablePercentage,
          }
        }
        return y
      }) || []
  )

  return (
    <TeamEmployeeRow
      isTotal
      monthList={monthList}
      employee={{
        name: 'Total',
        planned_hours: planned_hours,
        hours_per_week: 40,
        spent_hours_future: 0,
        spent_hours_past: 0,
        tariff: 0,
        billable_percentage: 100,
        projects: [],
        id: 'xyz-total',
      }}
      showEmployeeDetails=""
      // eslint-disable-next-line
      onPressShowEmployeeDetails={() => {}}
    />
  )
}
