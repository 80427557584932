import moment from 'moment'

export type MonthYearPair = [month: number, year: number]

type GetMonths = () => MonthYearPair[]
export const GetMonthsThisYear: GetMonths = () => {
  const startDate = moment()
  const endDate = moment().endOf('year')
  const dates = []

  for (let year = startDate.year(); year <= endDate.year(); year++) {
    const endMonth = year != endDate.year() ? 11 : endDate.month()
    const startMonth = year === startDate.year() ? startDate.month() : 0
    for (
      let currentMonth = startMonth;
      currentMonth <= endMonth;
      currentMonth =
        currentMonth > 12 ? currentMonth % 12 || 11 : currentMonth + 1
    ) {
      const month = currentMonth + 1
      const date: MonthYearPair = [month, year]
      dates.push(date)
    }
  }

  return dates
}

export const GetMonthsNextYear: GetMonths = () => {
  const startDate = moment().add(1, 'year').startOf('year')
  const endDate = moment().add(1, 'year').endOf('year')
  const dates = []

  for (let year = startDate.year(); year <= endDate.year(); year++) {
    const endMonth = year != endDate.year() ? 11 : endDate.month()
    const startMonth = year === startDate.year() ? startDate.month() : 0
    for (
      let currentMonth = startMonth;
      currentMonth <= endMonth;
      currentMonth =
        currentMonth > 12 ? currentMonth % 12 || 11 : currentMonth + 1
    ) {
      const month = currentMonth + 1
      const date: MonthYearPair = [month, year]
      dates.push(date)
    }
  }

  return dates
}
