import { Box, CircularProgress } from '@mui/material'

const styling = {
  position: 'relative',
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(0,0,0, .12)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
}

export const LoadingSkeleton = () => {
  return (
    <Box sx={styling}>
      <CircularProgress color="success" />
    </Box>
  )
}
