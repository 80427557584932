import styled from 'styled-components'

export const Content = styled.div`
  display: grid;
  gap: 10px;
`

export const Footer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
`
