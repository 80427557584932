import Sidebar from 'src/components/Sidebar/Sidebar'
import { DetailedTeam } from 'src/packages/api/types/Project'
import { MonthYearPair } from 'src/pages/helper'
import { TeamEmployees } from './TeamEmployees'

interface TeamPageDetailsProps {
  team: DetailedTeam
  monthList: MonthYearPair[]
}

const TeamPageDetails: React.FC<TeamPageDetailsProps> = ({
  team,
  monthList,
}) => {
  return (
    <>
      <TeamEmployees monthList={monthList} team={team} />
      <Sidebar zindex={8}></Sidebar>
    </>
  )
}
export default TeamPageDetails
