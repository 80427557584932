export const Logo = () => {
  return (
    <svg
      width={120}
      height={80}
      viewBox="0 0 184 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="logo1">
          <g id="nieuw-logo" transform="translate(-0.333333, 0.604587)">
            <polygon
              id="Fill-1"
              fill="#95AF3D"
              points="17.5818 38.9116587 11.3019481 52.0086187 4.98546667 38.9116587 0.333503704 38.9116587 0.333503704 63.130112 5.05446667 63.130112 5.05446667 49.014272 9.70472593 58.1986987 12.8974667 58.1986987 17.5162074 49.014272 17.5162074 63.130112 22.2346148 63.130112 22.2346148 38.9116587"
            ></polygon>
            <path
              d="M68.9772593,52.7725227 L64.0535556,52.7725227 L64.0535556,58.7270827 L68.9772593,58.7270827 C71.1162593,58.7270827 72.1350741,57.3643093 72.1350741,55.7318827 C72.1350741,54.099456 71.1162593,52.7725227 68.9772593,52.7725227 M68.6714444,42.942976 L64.0535556,42.942976 L64.0535556,48.5220693 L68.6714444,48.5220693 C70.6758519,48.5220693 71.796037,47.3982293 71.796037,45.7316693 C71.796037,44.064256 70.6758519,42.942976 68.6714444,42.942976 M69.4525926,62.9434027 L59.3334444,62.9434027 L59.3334444,38.7249493 L69.0454074,38.7249493 C73.7646667,38.7249493 76.5152963,41.3771093 76.5152963,45.528576 C76.5152963,48.2148693 74.7502593,49.949696 73.527,50.528256 C74.9879259,51.2075093 76.8551852,52.740096 76.8551852,55.970816 C76.8551852,60.494336 73.7646667,62.9434027 69.4525926,62.9434027"
              id="Fill-2"
              fill="#95AF3D"
            ></path>
            <polygon
              id="Fill-4"
              fill="#95AF3D"
              points="96.4840667 53.0100053 96.4840667 62.944512 91.7997333 62.944512 91.7997333 53.0100053 84.5325852 38.7260587 89.6607333 38.7260587 94.1764 48.4864853 98.6256222 38.7260587 103.752067 38.7260587"
            ></polygon>
            <path
              d="M119.083441,63.1466667 C116.504033,63.1466667 114.330959,62.26176 112.564219,60.49536 C111.510478,59.4397867 110.866478,58.2152533 110.593885,56.8541867 C110.322996,55.49312 110.187552,53.48864 110.187552,50.8330667 C110.187552,48.1809067 110.322996,46.17472 110.593885,44.81536 C110.866478,43.4193067 111.510478,42.2280533 112.564219,41.1741867 C114.330959,39.4052267 116.504033,38.5220267 119.083441,38.5220267 C123.735404,38.5220267 127.163256,41.2424533 127.978478,46.17472 L123.19107,46.17472 C122.683367,44.0994133 121.393663,42.7383467 119.117515,42.7383467 C117.826107,42.7383467 116.808996,43.1470933 116.095144,43.99872 C115.111256,45.0517333 114.908515,46.1397333 114.908515,50.8330667 C114.908515,55.5272533 115.111256,56.61696 116.095144,57.6699733 C116.808996,58.5207467 117.826107,58.9312 119.117515,58.9312 C121.393663,58.9312 122.683367,57.56928 123.19107,55.49312 L127.978478,55.49312 C127.163256,60.42624 123.70133,63.1466667 119.083441,63.1466667"
              id="Fill-6"
              fill="#95AF3D"
            ></path>
            <polygon
              id="Fill-8"
              fill="#95AF3D"
              points="179.46517 62.9433173 169.891207 48.079104 169.891207 62.9433173 165.171096 62.9433173 165.171096 38.724864 169.380096 38.724864 178.954911 53.5540907 178.954911 38.724864 183.675022 38.724864 183.675022 62.9433173"
            ></polygon>
            <path
              d="M43.6500852,57.6374613 C42.9720111,58.419968 41.9191222,58.9311147 40.6294185,58.9311147 C39.3380111,58.9311147 38.2519,58.419968 37.5729741,57.6374613 C36.6563815,56.6168747 36.4187148,55.4930347 36.4187148,50.8329813 C36.4187148,46.1746347 36.6563815,45.051648 37.5729741,44.032768 C38.2519,43.249408 39.3380111,42.7382613 40.6294185,42.7382613 C41.9191222,42.7382613 42.9720111,43.249408 43.6500852,44.032768 C44.5675296,45.051648 44.8392704,46.1746347 44.8392704,50.8329813 C44.8392704,55.4930347 44.5675296,56.6168747 43.6500852,57.6374613 M47.1486407,41.1741013 C45.4509,39.4725547 43.3783444,38.5219413 40.6294185,38.5219413 C37.8787889,38.5219413 35.7721593,39.4725547 34.0761222,41.1741013 C31.6313074,43.6223147 31.6977519,46.6499413 31.6977519,50.8329813 C31.6977519,55.0185813 31.6313074,58.046208 34.0761222,60.4952747 C35.7721593,62.195968 37.8787889,63.1465813 40.6294185,63.1465813 C43.3783444,63.1465813 45.4509,62.195968 47.1486407,60.4952747 C49.5943074,58.046208 49.5593815,55.0185813 49.5593815,50.8329813 C49.5593815,46.6499413 49.5943074,43.6223147 47.1486407,41.1741013"
              id="Fill-10"
              fill="#95AF3D"
            ></path>
            <path
              d="M148.722774,57.6374613 C148.0447,58.419968 146.992663,58.9311147 145.702107,58.9311147 C144.4107,58.9311147 143.324589,58.419968 142.644811,57.6374613 C141.72907,56.6168747 141.491404,55.4930347 141.491404,50.8329813 C141.491404,46.1746347 141.72907,45.051648 142.644811,44.032768 C143.324589,43.249408 144.4107,42.7382613 145.702107,42.7382613 C146.992663,42.7382613 148.0447,43.249408 148.722774,44.032768 C149.640219,45.051648 149.911959,46.1746347 149.911959,50.8329813 C149.911959,55.4930347 149.640219,56.6168747 148.722774,57.6374613 M152.220478,41.1741013 C150.522737,39.4725547 148.451885,38.5219413 145.702107,38.5219413 C142.95233,38.5219413 140.8457,39.4725547 139.148811,41.1741013 C136.704848,43.623168 136.771293,46.6499413 136.771293,50.8329813 C136.771293,55.0185813 136.704848,58.046208 139.148811,60.4952747 C140.8457,62.195968 142.95233,63.1465813 145.702107,63.1465813 C148.451885,63.1465813 150.522737,62.195968 152.220478,60.4952747 C154.666996,58.046208 154.63207,55.0185813 154.63207,50.8329813 C154.63207,46.6499413 154.666996,43.623168 152.220478,41.1741013"
              id="Fill-12"
              fill="#95AF3D"
            ></path>
            <path
              d="M151.002159,9.63336533 C150.986826,8.14600533 149.918604,5.67560533 145.677233,5.65853867 L145.672974,5.65853867 L145.667863,5.65853867 C141.428196,5.67560533 140.35827,8.14600533 140.343789,9.63336533 C140.337826,10.2281387 140.328456,12.087552 140.422159,13.7762987 C140.61553,17.2169387 142.803937,19.9450453 145.667863,19.9501653 L145.667863,19.9510187 L145.672974,19.9510187 L145.677233,19.9510187 L145.677233,19.9501653 C148.541159,19.9450453 150.73127,17.2169387 150.922937,13.7762987 C151.018344,12.087552 151.00727,10.2281387 151.002159,9.63336533"
              id="Fill-14"
              fill="#95AF3D"
            ></path>
            <path
              d="M145.68473,32.8971093 C148.754804,32.8971093 150.725989,33.7956693 152.758507,35.4451627 C153.480026,33.623296 154.804656,27.9844693 157.310804,26.7283627 C157.722248,26.5227093 158.090248,26.3597227 158.611581,26.214656 C168.945396,23.3406293 171.459211,21.3003093 174.088878,19.120896 C178.392433,15.554816 178.002285,12.7908693 177.579767,12.6790827 C177.579767,12.6790827 176.227878,14.725376 174.774619,15.7630293 C170.4191,18.872576 163.226063,23.114496 145.68047,23.053056 L145.68047,23.0496427 C128.129767,23.111936 120.933322,18.870016 116.576952,15.759616 C115.123693,14.7219627 113.771804,12.6756693 113.771804,12.6756693 C113.350137,12.787456 112.958285,15.5514027 117.264396,19.1174827 C119.894063,21.2960427 122.407878,23.337216 132.740841,26.2112427 C133.262174,26.355456 133.631026,26.519296 134.040767,26.7249493 C136.546915,27.981056 137.871544,33.6190293 138.594767,35.4417493 C140.626433,33.792256 142.610396,32.8971093 145.679619,32.8971093"
              id="Fill-16"
              fill="#95AF3D"
            ></path>
            <path
              d="M167.461044,15.6795733 C168.609341,14.70848 169.241415,13.64352 169.241415,12.52736 C169.241415,7.9936 158.790896,4.31744 145.902378,4.31744 C133.014711,4.31744 122.565896,7.9936 122.565896,12.52736 C122.565896,13.6597333 123.217563,14.7400533 124.399081,15.72224 L124.34797,15.7205333 C118.558785,14.0829867 114.962267,11.79264 114.962267,9.25824 C114.962267,4.28245333 128.815081,0.248746667 145.902378,0.248746667 C162.994785,0.248746667 176.845896,4.28245333 176.845896,9.25824 C176.845896,11.7943467 173.245119,14.0864 167.44997,15.7230933 L167.461044,15.6795733 Z"
              id="Fill-18"
              fill="#00AAD4"
            ></path>
            <path
              d="M126.594559,13.9049813 C126.594559,15.473408 128.800004,16.891648 132.358189,17.9122347 L132.484263,17.9395413 C131.746559,17.3259947 131.338522,16.6518613 131.338522,15.944448 C131.338522,14.0645547 134.2263,12.4261547 138.516226,11.5352747 L138.516226,8.70903467 C131.516559,9.55383467 126.594559,11.561728 126.594559,13.9049813"
              id="Fill-21"
              fill="#00AAD4"
            ></path>
            <path
              d="M152.685844,8.63957333 L152.685844,11.4129067 C157.3114,12.2705067 160.468363,13.9771733 160.468363,15.94496 C160.468363,16.6353067 160.079919,17.29408 159.376289,17.8965333 L159.355844,17.9374933 C162.9694,16.91776 165.215733,15.4875733 165.215733,13.90464 C165.215733,11.4948267 160.004956,9.43914667 152.685844,8.63957333"
              id="Fill-23"
              fill="#00AAD4"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
