/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
// DO NOT EDIT - Generated using Aegis
import {
  Decoder,
  constant,
  optional,
  string,
  boolean,
  number,
  null_,
  undefined_,
  object,
  array,
  either,
  either3,
  either4,
  either5,
  either6,
  either7,
  either8,
  either9,
} from 'decoders'
export const apiErrorDecoder = object({
  title: string,
  status: number,
  detail: optional(string),
  rawError: optional(string),
})
export const apiConfigDecoder = object({
  baseUrl: string,
  jwt: optional(string),
})
export const plannedHoursDecoder = object({
  project_id: optional(string),
  month: number,
  year: number,
  hours: number,
  type: string,
})
export const teamDecoder = object({
  id: string,
  name: string,
})
export const employeeDecoder = object({
  id: string,
  name: string,
  function: optional(either(string, null_)),
  teams: optional(either(array(teamDecoder), null_)),
})
export const organizationDecoder = object({
  id: string,
  name: string,
})
export const organizationProfileDecoder = object({
  id: string,
  organization: organizationDecoder,
})
export const simpleProjectDecoder = object({
  id: string,
  name: string,
  has_hours: optional(boolean),
})
export const projectDecoder = object({
  status: string,
  simplicate_value_spent: number,
  simplicate_value_budget: number,
  simplicate_amount_spent: number,
  simplicate_amount_budget: number,
  simplicate_other_costs: optional(number),
  start_date: string,
  end_date: string,
  employees: either(array(employeeDecoder), null_),
  project_manager: employeeDecoder,
  organization: optional(organizationDecoder),
  my_organization_profile: optional(organizationProfileDecoder),
  id: string,
  name: string,
  has_hours: optional(boolean),
})
export const detailedEmployeeDecoder = object({
  hours_per_week: number,
  avatar_small: optional(string),
  avatar_large: optional(string),
  spent_hours_future: number,
  spent_hours_past: number,
  tariff: number,
  billable_percentage: either(number, null_),
  planned_hours: either(array(plannedHoursDecoder), null_),
  projects: either(array(simpleProjectDecoder), null_),
  id: string,
  name: string,
  function: optional(either(string, null_)),
  teams: optional(either(array(teamDecoder), null_)),
})
export const detailedTeamDecoder = object({
  employees: array(detailedEmployeeDecoder),
  id: string,
  name: string,
})
export const teamsResponseDecoder = either(array(teamDecoder), null_)
export const projectsResponseDecoder = array(projectDecoder)
export const detailedProjectDecoder = object({
  employees: either(array(detailedEmployeeDecoder), null_),
  status: string,
  simplicate_value_spent: number,
  simplicate_value_budget: number,
  simplicate_amount_spent: number,
  simplicate_amount_budget: number,
  simplicate_other_costs: optional(number),
  start_date: string,
  end_date: string,
  project_manager: employeeDecoder,
  organization: optional(organizationDecoder),
  my_organization_profile: optional(organizationProfileDecoder),
  id: string,
  name: string,
  has_hours: optional(boolean),
})
export const plannedHoursListDecoder = array(plannedHoursDecoder)
export const updateHoursRequestDecoder = object({
  data: plannedHoursListDecoder,
})
export const employeesResponseDecoder = either(array(employeeDecoder), null_)
export const jwtResponseDecoder = object({
  jwt: string,
})
export const loginRequestDecoder = object({
  email: string,
  password: string,
})
export const passwordResetStartRequestDecoder = object({
  email: string,
})
export const passwordResetCompleteRequestDecoder = object({
  resetToken: string,
  password: string,
})
export const profileResponseDecoder = object({
  firstName: string,
})
export const registerRequestDecoder = object({
  email: string,
  password: string,
  firstName: string,
})
export const validateAccountRequestDecoder = object({
  token: string,
})
