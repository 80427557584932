import config from 'src/config'
import { ApiConfig } from 'src/packages/api'

export default () => {
  const token = localStorage.getItem('token')
  const configResult: ApiConfig = {
    jwt: token || undefined,
    baseUrl: config.baseUrl,
  }
  const result = {
    config: configResult,
  }
  return result
}
