import NumberLabel from 'src/components/Label/NumberLabel'
import Cell from 'src/components/Table/Cell'
import THead from 'src/components/Table/THead'
import { DetailedProject } from 'src/packages/api/types/Project'
import { getFuturePHValuesByProj } from 'src/pages/helper'
import theme from 'src/theme/theme'
import styled from 'styled-components'
import { ProjectDetailsList } from './styles'

const Container = styled.div`
  background-color: ${theme.colors.tertiary};
`

interface ProjectDetailsProps {
  project: DetailedProject
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({ project }) => {
  const futurePH = getFuturePHValuesByProj(project)
  const spendingHistorical =
    project.simplicate_value_spent - futurePH.value_spent
  const availableBudget = project.simplicate_value_budget - spendingHistorical
  futurePH.value_planned

  return (
    <Container>
      <THead color="#B8D35B">
        <Cell width={358} hcenter="flex-start">
          <strong>Project details</strong>
        </Cell>
      </THead>
      <ProjectDetailsList>
        <span>Budget Simplicate (€)</span>
        <NumberLabel
          width={90}
          number={project.simplicate_value_budget}
          maxDec={1}
        />

        <span>Spent before this month (€)</span>
        <NumberLabel width={90} number={spendingHistorical} maxDec={1} />

        <span>Cost of Planned Hours (€)</span>
        <NumberLabel width={90} number={futurePH.value_planned} maxDec={1} />

        <span>Third party Cost Simplicate (€)</span>
        <NumberLabel
          width={90}
          number={project.simplicate_other_costs || 0}
          maxDec={1}
        />

        <span>Available Budget (€)</span>
        <NumberLabel
          width={90}
          number={
            availableBudget -
            futurePH.value_planned -
            (project.simplicate_other_costs || 0)
          }
          maxDec={1}
        />
      </ProjectDetailsList>
    </Container>
  )
}

export default ProjectDetails
