import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import { useNavigate } from 'react-router-dom'
import Avatar from 'src/components/Avatar/Avatar'
import NumberLabel from 'src/components/Label/NumberLabel'
import Cell from 'src/components/Table/Cell'
import THead from 'src/components/Table/THead'
import { DetailedEmployee } from 'src/packages/api/types/Employee'
import {
  EmployeeDetailsOverlayContainer,
  List,
  ProjectDetails,
  ProjectName,
} from './styles'

interface EmployeeDetailsOverlayProps {
  employee: DetailedEmployee
}

const EmployeeDetailsOverlay: React.FC<EmployeeDetailsOverlayProps> = ({
  employee,
}) => {
  const navigate = useNavigate()

  const handleGoToEmployee = () => {
    navigate('/employees?id=' + employee.id)
  }

  const handleGoToProject = (projectID: string) => {
    navigate('/project/' + projectID)
  }

  return (
    <EmployeeDetailsOverlayContainer>
      <THead color="#B8D35B">
        <Cell onClick={handleGoToEmployee} width={358} hcenter="flex-start">
          <Avatar employee={employee} />
          <strong>{employee.name}</strong>
        </Cell>
      </THead>
      <List>
        {employee.projects
          ?.sort((a, b) => (a.name < b.name ? -1 : 1))
          .map((proj) => {
            try {
              const planned_hours = employee.planned_hours || []
              const hours = planned_hours
                .filter((y) => y.project_id === proj.id)
                .map((y) => y.hours)
                .reduce((a, b) => a + b, 0)

              if (!proj) return null
              return (
                <div key={proj.id}>
                  <ProjectName onClick={() => handleGoToProject(proj.id)}>
                    {proj.name}
                  </ProjectName>
                  <ProjectDetails>
                    <WorkHistoryIcon />
                    <NumberLabel width={56} number={hours} invertColor />
                  </ProjectDetails>
                </div>
              )
            } catch {
              return <div>Something went wrong</div>
            }
          })}
      </List>
    </EmployeeDetailsOverlayContainer>
  )
}

export default EmployeeDetailsOverlay
