import theme from 'src/theme/theme'
import styled from 'styled-components'

interface ListItemStylingProps {
  bgColor?: string
  focus?: boolean
}

export const Container = styled.div<ListItemStylingProps>`
  display: grid;
  grid-template-columns: 200px 58px 1fr;
  grid-template-rows: 58px;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : props.focus ? '#eee' : '#fff'};
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: ${(props) => (props.bgColor ? props.bgColor : '#eee')};
  }
`

export const EmployeeDetailsOverlayContainer = styled.div`
  height: 100vh;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

interface RowStylingProps {
  columns?: string
}

export const Row = styled.div<RowStylingProps>`
  display: grid;
  grid-template-columns: ${(props) => props.columns};
  align-items: center;
`

export const ProjectDetailsList = styled.div`
  padding: 0 12px;
  color: #fff;

  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
`

export const List = styled.div`
  background-color: transparent;
  height: calc(100vh - 174px);
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const ProjectName = styled.div`
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  min-height: 58px;
  background-color: ${theme.colors.tertiary};
  color: #fff;
  box-sizing: border-box;
  font-size: 14px;
`

export const ProjectDetails = styled.div`
  padding: 4px 12px;
  display: grid;
  grid-template-columns: 24px auto;
  gap: 20px;
  align-items: center;
  height: 58px;
  background-color: #fff;
  color: ${theme.colors.tertiary};
  box-sizing: border-box;
`
