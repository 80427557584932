import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import React from 'react'
import Button from 'src/components/Button/Button'
import Sidebar from 'src/components/Sidebar/Sidebar'
import Cell from 'src/components/Table/Cell'
import Table from 'src/components/Table/Table'
import TBody from 'src/components/Table/TBody'
import THead from 'src/components/Table/THead'
import TextInput from 'src/components/TextInput/TextInput'
import { Team } from 'src/packages/api/types/Project'

interface FiltersProps {
  filter: FilterProps
  setFilters: (key: string, value: string | null) => void
  clear: () => void
  teams: Team[]
}

export interface FilterProps {
  name: string
  role: string
  team: string
}

export const initialProjectFilter = {
  name: '',
  role: '',
  team: '',
}

const Filters: React.FC<FiltersProps> = ({
  setFilters,
  filter,
  clear,
  teams,
}) => {
  return (
    <Sidebar zindex={2}>
      <Table>
        <THead color="#B8D35B">
          <Cell hcenter="flex-start">
            <strong>Filter employees</strong>
          </Cell>
        </THead>
        <TBody fit>
          <FormControl sx={{ m: 1, minWidth: 236 }}>
            <TextInput
              label="Name"
              type="text"
              value={filter.name}
              variant="standard"
              onChange={(value: string) => setFilters('name', value)}
            />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 236 }}>
            <TextInput
              label="Role"
              type="text"
              value={filter.role}
              variant="standard"
              onChange={(value: string) => setFilters('role', value)}
            />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 236 }}>
            <p>Team</p>
            <Select
              label="Team"
              value={filter.team || ''}
              onChange={(event: SelectChangeEvent) =>
                setFilters('team', event.target.value)
              }
            >
              <MenuItem value="">
                <em>Select team</em>
              </MenuItem>
              {teams.map((team) => {
                return (
                  <MenuItem key={team.id} value={team.id}>
                    {team.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 236 }}>
            <Button title={'Clear'} onClick={clear} />
          </FormControl>
        </TBody>
      </Table>
    </Sidebar>
  )
}

export default Filters
