import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useProjectsContext } from 'src/data/ProjectsContext'
import useAuthorizedApiConfig from 'src/helpers/useAuthorizedApiConfig'
import Layout from 'src/layouts/Layout'
import ProjectPage from './ProjectPage'

export default function Page() {
  const { id } = useParams()
  const { projectDetails, fetchProjectDetails, isLoaded } = useProjectsContext()

  useEffect(() => {
    if (id) {
      if (isLoaded) {
        fetchProjectDetails(id)
      }
    }
  }, [id, isLoaded])

  const project = (id && projectDetails[id]) || null

  const { config } = useAuthorizedApiConfig()

  return (
    <Layout>
      <ProjectPage project={project} config={config} />
    </Layout>
  )
}
