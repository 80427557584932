import { useEffect, useState } from 'react'
import useAuthorizedApiConfig from 'src/helpers/useAuthorizedApiConfig'
import { getProjects } from 'src/packages/api'
import { ProjectsResponse } from 'src/packages/api/types/Project'

const useProjects = (): ProjectsResponse | null => {
  const { config } = useAuthorizedApiConfig()
  const [projects, setProjects] = useState<ProjectsResponse | null>(null)

  useEffect(() => {
    try {
      console.log('start fetching projects')
      getProjects(config).then((res) => {
        setProjects(res)
        localStorage.setItem('projects', JSON.stringify(res))
        console.log('fetch projects complete')
      })
    } catch (error) {
      console.log('Error trying to fetch projects with useProjects hook')
      console.error(error)
    }
  }, [])

  return projects
}

export default useProjects
