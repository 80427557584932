import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Date from 'src/components/Date/Date'
import Cell from 'src/components/Table/Cell'
import styled from 'styled-components'
import { ProjectListItemProps } from './FindProjectItem.types'
import { State } from 'src/components/Status/Status'

export const Container = styled(ListItem)`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ProjectListItem: React.FC<ProjectListItemProps> = ({ project }) => {
  const navigate = useNavigate()

  return (
    <Container
      onClick={() => {
        navigate('/project/' + project.id)
      }}
      title={`Naar ${project.name}`}
      disableGutters
      disablePadding
      secondaryAction={
        <IconButton aria-label="goTo">
          <ChevronRightIcon />
        </IconButton>
      }
    >
      <Cell hcenter="flex-start">{project.name}</Cell>
      <Cell width={120}>
        <State active={project.has_hours} />
      </Cell>
      <Cell width={220} hcenter="center">
        {project.project_manager.name}
      </Cell>
      <Cell width={126} hcenter="center">
        <Date date={project.start_date} title="Project has no start date" />
      </Cell>
      <Cell width={150} hcenter="center">
        {project.employees?.length || 0}
      </Cell>
    </Container>
  )
}

export default ProjectListItem
