import useProjects from 'src/data/useProjects'
import Layout from 'src/layouts/Layout'
import ProjectsPage from './ProjectsPage'

export default function Page() {
  const projects = useProjects()

  // forward data to client component
  return (
    <Layout>
      <ProjectsPage projects={projects} />
    </Layout>
  )
}
