import { Search } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { Small } from 'src/components/Typography/Typography'
import {
  InputContainer,
  InputElement,
  NumberElement,
  SearchContainer,
  SearchInputelement,
} from './TextInput.styles'
import TextInputProps, { NumberInputProps } from './TextInput.types'

const TextInput: React.FC<TextInputProps> = ({
  variant,
  type,
  label,
  defaultText,
  placeholder,
  value,
  onChange,
  validatorFunc,
  validationError,
}) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validatorFunc) {
      validatorFunc('', true)
    }

    onChange(event.target.value)
  }

  const blurHandler = () => {
    if (validatorFunc) {
      validatorFunc(value || '')
    }
  }

  const hasError = !!validationError

  return (
    <InputContainer>
      <InputElement
        onChange={changeHandler}
        onBlur={blurHandler}
        error={hasError}
        id={label}
        label={label}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultText}
        helperText={validationError}
        variant={variant}
        type={type}
      />
    </InputContainer>
  )
}

export default TextInput

export const NumberInput: React.FC<NumberInputProps> = ({
  label,
  width,
  placeholder,
  value,
  onChange,
  validatorFunc,
  validationError,
  disabled,
  pastHours,
  onBlur,
}) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validatorFunc) {
      validatorFunc(0, true)
    }

    if (onChange) {
      onChange(event.target.valueAsNumber)
    }
  }

  const blurHandler = () => {
    if (validatorFunc) {
      validatorFunc(value || 0)
    }
    if (onBlur) {
      onBlur()
    }
  }

  const hasError = !!validationError

  return (
    <InputContainer>
      <NumberElement
        onChange={changeHandler}
        onBlur={blurHandler}
        id={label}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        pastHours={pastHours}
        width={width}
      />
      {hasError && <Small>{validationError}2</Small>}
    </InputContainer>
  )
}

export const SearchbarInput: React.FC<TextInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  validatorFunc,
  width,
}) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validatorFunc) {
      validatorFunc('', true)
    }

    onChange(event.target.value)
  }

  const blurHandler = () => {
    if (validatorFunc) {
      validatorFunc(value || '')
    }
  }

  return (
    <SearchContainer>
      <IconButton>
        <Search />
      </IconButton>
      <SearchInputelement
        onChange={changeHandler}
        onBlur={blurHandler}
        id={label}
        placeholder={placeholder}
        value={value}
        width={width}
      />
    </SearchContainer>
  )
}
