import GroupIcon from '@mui/icons-material/Group'
import { IconButton } from '@mui/material'
import { useState } from 'react'
import Header from 'src/components/Header/Header'
import ProjectListItem from 'src/components/ListItems/FindProjectItem/FindProjectItem'
import Cell from 'src/components/Table/Cell'
import Table from 'src/components/Table/Table'
import TBody from 'src/components/Table/TBody'
import THead from 'src/components/Table/THead'
import { SearchbarInput } from 'src/components/TextInput/TextInput'
import Toggle from 'src/components/Toggle/Toggle'
import { Organization, ProjectsResponse } from 'src/packages/api/types/Project'
import { getProjectsByDetails } from 'src/pages/helper'
import { LoadingSkeleton } from '../project/[id]/components/LoadingSkeleton'
import Filters, {
  FilterProps,
  initialProjectFilter,
} from './components/filters'

interface ProjectsPageProps {
  projects: ProjectsResponse | null
}

export default function ProjectsPage({ projects }: ProjectsPageProps) {
  const [filter, setFilter] = useState<FilterProps>({
    name: '',
    start: null,
    end: null,
    projectLeader: '',
    region: '',
  })
  const [showFilterOptions, setShowFilterOptions] = useState(false)

  const handleShowFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions)
  }

  const handleFilter = (key: string, value: string | null) => {
    setFilter({ ...filter, [key]: value })
  }

  const handleClear = () => {
    setFilter(initialProjectFilter)
  }

  if (!projects) {
    return <LoadingSkeleton />
  }

  //Sort alphabetically
  const projectsSorted = projects.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    return 1
  })

  //Apply filters
  const projectsFiltered = getProjectsByDetails(
    projectsSorted,
    filter.name,
    filter.start || undefined,
    filter.end || undefined,
    filter.projectLeader,
    filter.region
  )
  const projectManagers = projects.map((p) => p.project_manager)
  const projectRegions = projects
    .filter((p) => p.my_organization_profile)
    .map((p) => p.my_organization_profile?.organization)
  const projectRegionsFiltered = projectRegions.filter((x) =>
    Boolean(x)
  ) as Organization[]

  return (
    <>
      <Header
        title="Mobycon projects"
        options={
          <Toggle
            label="Filters"
            active={showFilterOptions}
            onClick={handleShowFilterOptions}
          />
        }
      />
      <Table>
        <div>
          <THead color="#B8D35B">
            <Cell hcenter="flex-end">
              <SearchbarInput
                label="Name"
                value={filter.name}
                placeholder="Search by name"
                type="text"
                variant="outlined"
                onChange={(value: string) => handleFilter('name', value)}
                width={300}
              />
            </Cell>
          </THead>
          <THead color="#F7F7F7">
            <Cell hcenter="flex-start">Name</Cell>
            <Cell width={100} hcenter="flex-start">
              Active
            </Cell>
            <Cell width={220} hcenter="center">
              Project Leader
            </Cell>
            <Cell width={126} hcenter="center">
              Start date
            </Cell>
            <Cell width={150} hcenter="center">
              <IconButton aria-label="goTo">
                <GroupIcon />
              </IconButton>
            </Cell>
          </THead>
        </div>
        <TBody scrollableX="auto" scrollableY="auto">
          {projectsFiltered.map((project) => (
            <ProjectListItem key={project.id} project={project} />
          ))}
        </TBody>
      </Table>
      {showFilterOptions && (
        <Filters
          clear={handleClear}
          projectManagers={projectManagers}
          projectRegions={projectRegionsFiltered}
          filter={filter}
          setFilters={handleFilter}
        />
      )}
    </>
  )
}
