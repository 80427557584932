import Sidebar from 'src/components/Sidebar/Sidebar'
import { DetailedProject } from 'src/packages/api/types/Project'
import { MonthYearPair } from 'src/pages/helper'
import Employees from './components/Employees'
import ProjectDetails from './components/ProjectDetails'
import { ConfigProps } from './ProjectPage'

interface ProjectPageDetailsProps extends ConfigProps {
  project: DetailedProject
  monthList: MonthYearPair[]
}

const ProjectPageDetails: React.FC<ProjectPageDetailsProps> = ({
  project,
  monthList,
  config,
}) => {
  return (
    <>
      <Employees monthList={monthList} project={project} config={config} />
      <Sidebar zindex={8}>
        <ProjectDetails project={project} />
      </Sidebar>
    </>
  )
}
export default ProjectPageDetails
